@import url("../shared/Fonts/fonts.css");

:root {
  --mfg-red: rgba(226, 55, 63, 1);
  --border-radius: 0.25rem;
  --text-color: rgb(255, 255, 255);
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto-Medium";
  color: var(--text-color);
}

.mainContainer{
  display: flex;
  flex-direction: column;
  top: 0;
  width: 100%;
  position: fixed;
  margin: 5rem 0rem 0 0rem;
  z-index: 30;
}

.penaltyInvoiceMainContainer{
  display: flex;
  flex-direction: column;
  top: 0;
  width: 20%;
  position: fixed;
  margin: 5rem 0rem 0 0rem;
  z-index: 30;
  left: 80%;
}

.mainContainerHidden{
  display: none;
  top: 0;
  width: 100%;
  position: fixed;
  margin: 5rem 0rem 0 0rem;
  flex-shrink: 2;
  z-index: 30;

}

.errorPopUp {
  position: relative;
  z-index: 20;
}

.errorPopUp {
  display: flex;
  justify-content: right;
}

.errorContainer,
.errorMsgHide {
  display: flex;
  background-color: var(--mfg-red);
  border-radius: var(--border-radius);
  padding: 0.6rem;
  margin-top: 0.6rem;
  margin-right: 0.6rem;
  width: 40%;
  min-height: 2.5rem;
  justify-content: space-between;
  transform: translateX(+120%);
}

.penaltyInvoiceErrorContainer{
  display: flex;
  background-color: var(--mfg-red);
  border-radius: var(--border-radius);
  padding: 0.6rem;
  margin-top: 0.6rem;
  margin-right: 0.6rem;
  animation: slide-in 0.5s ease;
  animation-fill-mode: forwards;
  width: 100%;
  min-height: 2.5rem;
  justify-content: space-between;
  transform: translateX(+120%);
}

.errorContainer {
  animation: slide-in 0.5s ease;
  animation-fill-mode: forwards;
}

.errorMsgHide {
  animation: slide-out 0.5s ease;
  animation-fill-mode: forwards;
}

.errorMsgContainer {
  display: flex;
  margin-top: 0.4rem;
}

.close {
  cursor: pointer;
  padding-right: 0.3rem;
  font-size: 1.25rem;
}

.helpText {
  margin-bottom: 0rem;
  margin-top: 0.2rem;
}

.errorIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.6rem;
  margin-left: 0.3rem;
}
.logoutText{
  font-size: 1rem;
}


.crossIcon {
  transform: rotate(45deg);
}

@keyframes slide-in {
  0% {
    transform: translateX(+100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(120%);
  }
}

@media only screen and (max-width: 900px) {
  .errorContainer {
    width: 50%;
  }
  .penaltyInvoiceErrorContainer{
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .errorContainer, .penaltyInvoiceErrorContainer {
    width: 100%;
  }
}

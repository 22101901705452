@import url("../../shared/Fonts/fonts.css");

:root {
  --input-calendar-text: rgba(68, 68, 68, 1);
  --light-white: rgba(255, 255, 255, 0.7);
  --calendar-border: rgba(204, 204, 204, 1);
  --mfg-red: rgba(226, 55, 63, 1);
  --pure-white: rgb(255, 255, 255);

  --calendar-08rem: 0.8rem;
}

.react-datepicker-wrapper {
  display: flex;
  width: 100%;
}

.react-datepicker__input-container input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: var(--calendar-08rem);
  font-family: "Roboto-Regular";
  padding: 0rem 0rem 0rem 0.5rem;
  border: 1px solid var(--global-border-color);
  border-radius: 4px;
  width: 96%;
  height: 2.2rem;
  position: relative;
  color: var(--input-calendar-text);
  background: url("../../assets/icons/calendar.svg") no-repeat;
  background-position: 95% center;
  margin: 0rem 0px 0px 0px;
  background-color: var(--pure-white);
  cursor: pointer;
}

.dashboardFilter input {
  color: var(--pure-white);
  background: url("../../assets/icons/calendarLight.svg") no-repeat;
  background-position: 95% center;
}
.react-datepicker-popper {
  z-index: 11;
}

.stationDate {
  display: block;
  width: 16.5rem;
}

.react-datepicker__input-container input:focus {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("../../assets/icons/calendar.svg") no-repeat;
  background-position: 95% center;
}

.dashboardFilter input:focus {
  background: url("../../assets/icons/calendarLight.svg") no-repeat;
  background-position: 95% center;
}

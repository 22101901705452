.mainContainer {
  padding: 0.5rem 5rem 0.5rem 5rem;
}

.leftFilterContainer{
  display: flex;
  flex-wrap: wrap;
}
.stationHeaderContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.stationContainer {
  margin-top: 1rem;
}

.filterContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.theadHeader > .smallField {
  min-width: 7rem;
}

.theadHeader > .largeField {
  min-width: 15.5rem;
}

.theadHeader > .mediumField {
  min-width: 14rem;
}

.createBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.3rem;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 4px;
  background-color: var(--mfg-red);
}
.createBtn > p {
  padding-right: 10px;
  color: var(--pure-white);
}

.addContainer {
  display: flex;
}
@media only screen and (max-width: 600px) {
  .createBtn {
    width: 10rem;
  }
}

@media only screen and (max-width: 963px) {
  .filterContainer {
    width: 100%;
    padding-left: 0rem;
  }

  .mainContainer {
    padding: 1rem 2rem 1rem 2rem;
  }
}

@media only screen and (max-width: 615px) {
  .filterContainer {
    display: flex;
  }

  .searchBoxContainer {
    display: flex;
    flex-wrap: wrap;
  }

  .mainContainer {
    padding: 0.5rem 1rem 0.5rem 1rem;
  }
}

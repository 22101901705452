@font-face {
  font-family: 'Roboto-Bold';
  src: url('../../assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 900;
  font-style: bold;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../../assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

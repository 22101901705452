
.paginationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    justify-content: space-between;
    background-color: var(--pure-white);
    padding: 0rem 1rem 0rem 1rem;
    box-shadow: 0px 1px 8px var(--box-shadow);
  }
  
  .pageCount > p {
    font-size: var(--global-table-09rem);
    font-family: "Roboto-Regular";
    letter-spacing: 0.01rem;
    color: rgba(68, 68, 68, 1);
  }
  .pageCount{
    display: flex;
    align-items: center;
  }
  
  .paginationBtn {
    width: 50%;
    list-style: none;
    display: flex;
    justify-content: right;
    font-family: "Roboto-Regular";
    font-size: var(--global-table-08rem);
  }
  
  .paginationBtn a {
    border: 1px solid var(--table-border-color);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    cursor: pointer;
    color: var(--mgf-blue);
  }
  
  .paginationActive > a {
    color: var(--pure-white);
    background-color: var(--mgf-blue);
  }
  
  .paginationValues {
    color: var(--pagintionNumber-color);
    font-weight: 600;
    margin-left: 0.5rem;
  }
  
  .prev {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  
  .next {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  
.pageContainer{
  display: flex;
  align-items: end;
  justify-content: flex-end;
  padding: 10px;
}

.dropDown{
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: var(--global-09rem);
    font-family: "Roboto-Regular";
    border: 1px solid var(--global-border-color);
    padding: 0rem 1rem 0rem 1rem;
    margin: 0;
    width: 6rem;
    height: 2rem;
    position: relative;
    color: var(--btnText-grey);
    background: url("../assets/icons/arrowDownFilled.svg") no-repeat;
    background-position: 95% center;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 0.5rem;
}

@import url("../shared/Fonts/fonts.css");

:root {
  /* Color varibales */
  --global-border-color: rgba(0, 0, 0, 0.08);
  --pure-white: rgba(255, 255, 255, 1);
  --background-color: rgba(250, 250, 250, 1);
  --btnText-grey: rgba(102, 102, 102, 1);
  --dark-grey: rgba(68, 68, 68, 1);
  --border-grey: rgba(102, 102, 102, 0.671);
  --mfg-red: rgba(226, 55, 63, 1);

  /* Font size varibales */
  --global-09rem: 0.9rem;
  --global-1rem: 1rem;
  --global-17rem: 1.7rem;
}

.mainContainer {
  background-color: var(--background-color);
  background-color: red;
  margin: 0;
  padding: 0 1rem 0 2rem;
}
.multiselectContainer{
  width: 18rem;
}
.filtersContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;
  margin-right: 0rem;
  margin-bottom: 0px;
}

.moduleFilterContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.showIcon{
  margin: 0.5rem;
}

.filterContainerOne {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.filterContainerTwo {
  display: flex;
  flex: wrap;
  justify-content: flex-end;
  height: 100%;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.moduleCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBox {
  display: flex;
  align-items: center;
  background-color: var(--pure-white);
  border: 1px solid var(--global-border-color);
  border-radius: 4px;
  padding: 0rem 0.5rem 0rem 0.5rem;
  margin: 0;
  width: 18rem;
  height: 2.2rem;
  margin-right: 0.8rem;
}

.searchIcon {
  margin-right: 0.5rem;
}

.searchBox > input {
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 97%;
  font-size: var(--global-09rem);
}

.selectionBox {
  background-color: var(--pure-white);
  margin: 0 0.5rem 0 0;
  cursor: pointer;
}

.selectionBox > select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: var(--global-09rem);
  font-family: "Roboto-Regular";
  border: 1px solid var(--global-border-color);
  padding: 0rem 1rem 0rem 1rem;
  margin: 0;
  width: 14rem;
  height: 2.3rem;
  position: relative;
  color: var(--btnText-grey);
  background: url("../assets/icons/arrowDownFilled.svg") no-repeat;
  background-position: 95% center;
  border-radius: 4px;
  cursor: pointer;
}

.selectionBoxs > select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: var(--global-09rem);
  font-family: "Roboto-Regular";
  border: 1px solid var(--global-border-color);
  padding: 0rem 1rem 0rem 1rem;
  margin: 0;
  width: 10rem;
  height: 2.3rem;
  position: relative;
  color: var(--btnText-grey);
  background: url("../assets/icons/arrowDownFilled.svg") no-repeat;
  background-position: 95% center;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;
}

.inputBox{
  width: 10rem;
  height: 2rem;
}

.selectionBox > select:focus {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("../assets/icons/arrowUpFilled.svg") no-repeat;
  background-position: 95% center;
}

.selectionBox > select > option {
  color: var(--btnText-grey);
  font-size: var(--global-09rem);
  font-family: "Roboto-Regular";
}
.multiselect > div > select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid var(--border-grey);
  padding: 0rem 1rem 0rem 1rem;
  margin: 0;
  border-radius: 2px;
  width: 17rem;
  height: 2.3rem;
  position: relative;
  color: var(--btnText-grey);
  background: url("../assets/icons/arrowDownFilled.svg") no-repeat;
  background-position: 95% center;
  border-radius: 4px;
  font-family: "Roboto-Regular";
  font-size: var(--inputs-09rem);
  color: var(--dark-grey);
  font-weight: normal;
}

.multiselect > div > select:focus {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("../assets/icons/arrowUpFilled.svg") no-repeat;
  background-position: 95% center;
}

.multiselect > div > select > option {
  font-family: "Roboto-Medium";
  font-size: var(--inputs-09rem);
  color: var(--dark-grey);
}

.filterText,
.displayText,
.displayTextLower,
.readOnlyText {
  color: var(--btnText-grey);
  font-size: var(--global-09rem);
  font-family: "Roboto-Regular";
  margin: 0.8rem 1rem 0.8rem 0;
  padding: 0;
  display: flex;
}

.readOnlyText {
  color: var(--btnText-grey);
}

.errorMessage,
.errorMessageShow {
  color: var(--mfg-red);
  font-size: var(--global-09rem);
  font-family: "Roboto-Regular";
  margin: 0rem 0 0rem 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  visibility: hidden;
  text-align: start;
  max-width: 16rem;
}

.errorMessageShow {
  visibility: visible;
}

.displayText {
  color: var(--dark-grey);
  font-family: "Roboto-Medium";
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}

.displayTextLower {
  text-transform: lowercase;
  color: var(--dark-grey);
  font-size: var(--global-09rem);
  font-family: "Roboto-Regular";
  font-weight: 600;
}

.hiddenTag {
  color: var(--btnText-grey);
  margin-bottom: 0.4rem;
  display: flex;
  visibility: hidden;
}
.addContainer{
  display: flex;
  flex-direction: column-reverse;
  margin: 1rem 0 0 0;
}
.addContainer > button {
  height: 2.2rem;
  border: none;
  cursor: pointer;
  padding: 0.5rem 2rem;
  background-color: var(--mfg-red);
  border-radius: 4px;
}

.dateContainer {
  width: max-content;
  margin: 0 1rem 0 0;
}
.multiselect {
  width: 200px;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  font-weight: bold;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#checkboxes {
  display: none;
  border: 1px #dadada solid;
  position: absolute;
  z-index: 2;
  background: rgb(255, 255, 255);
  width: 15.5rem;
  height: 6rem;
  overflow: scroll;
  scroll-behavior: smooth;
  padding: 10px;
}

#checkboxes label {
  display: block;
  color: var(--btnText-grey);
  font-size: var(--inputs-09rem);
  font-family: "Roboto-Regular";
  padding-top: 4px;
}

#checkboxes label:hover {
  background-color: #1e90ff;
}

.listTag{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 12px;
}

.inputContainer{
  display: flex;
  flex-direction: column;
}
.listItem{
  display: flex;
  align-items: center;
  background-color: var(--pure-white);
  border-bottom: 1px solid var(--global-border-color);
  border-right: 1px solid var(--global-border-color);
  border-left: 1px solid var(--global-border-color);
  padding: 0rem 0.5rem 0rem 0.5rem;
  color : var(--dark-grey);
  margin: 0;
  width: 18rem;
  height: 3rem;
  overflow: hidden;
}
.userDataContainer{
  position: absolute;
  overflow: scroll;
  max-height: 12rem;
}
.searchBoxSelect{
  color: var(--dark-grey) ;
  font-size: var(--global-1rem);
  font-family: "Roboto-Medium";
}

@media (min-width: 1001px) and (max-width: 1280px) {
  .moduleFilterContainer {
    display: flex;
    justify-content: space-between;
  }

  .filterContainerOne {
    display: flex;
    flex: 2;
    justify-content: space-between;
  }

  .filterContainerTwo {
    display: flex;
    flex: wrap;
  }
}

@media (min-width: 601px) and (max-width: 1000px) {
  .moduleFilterContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
  }

  .filterContainerOne {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .filterContainerTwo {
    display: flex;
    flex: wrap;
  }

  .searchBox > input::-webkit-input-placeholder {
    font-size: var(--global-09rem);
  }
}

@media only screen and (max-width: 600px) {
  .searchBox {
    max-width: 14rem;
    height: 2.2rem;
  }

  .searchIcon {
    margin-right: 0.2rem;
  }

  .searchBox > input {
    font-size: var(--global-09rem);
  }

  .selectionBox > select {
    width: 10rem;
  }

  .selectionBox > select > option {
    color: var(--grey);
    font-size: var(--global-09rem);
    font-family: "Roboto-Regular";
  }

  .dateContainer {
    width: 15rem;
  }

  .moduleFilterContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
  }

  .filterContainerOne {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .filterContainerTwo {
    display: flex;
    flex: wrap;
  }
}

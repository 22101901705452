.plusIconStyle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
		margin: 1rem;
}

.mailContainer {
    overflow-y: scroll;
    height: 14rem;
    border: 0.5px solid rgb(228 221 221 / 67%);
    border-radius: 4px;
}

.mailRow {
    display: flex;
    flex-direction: row;
    align-items: center;
		margin-left: 2rem;
}

.formFieldContainer {
  display: flex;
  flex-direction: column;
  min-width: 22rem;
}

.formFieldContainer > p {
  text-align: left;
  color: var(--form-label);
  font-size: var(--base-08rem);
  font-family: "Roboto-Regular";
}

.customRowInput{
	padding-right: 10px;
	margin-top: 10px;
}

.rowItems{
  padding-right: 10px;
}

.iconStyle{
  width: 1rem;
}

.editIconStyle{
 display:flex;
}
@import url("../shared/Fonts/fonts.css");

:root {
  /* Color varibales */
  --dark-grey: rgba(68, 68, 68, 1);
  --btnText-grey: rgba(102, 102, 102, 1);
  --border-grey: rgba(153, 153, 153, 0.363);
  --pure-white: rgba(255, 255, 255, 1);
  --mfg-light-red: rgba(255, 74, 74, 0.315);

  /* Font size varibales */
  --popup-08rem: 0.8rem;
  --popup-12rem: 1rem;
}

.overlay, .logOutoverlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 20;
}

.logOutoverlay{
  z-index: 30;
}

.popup {
  margin: 10% auto;
  padding: 1rem;
  background-color: var(--pure-white);
  border-radius: 5px;
  width: 24%;
  position: relative;
}

.topSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.topSection > p {
  text-align: start;
  font-size: var(--popup-12rem);
  color: var(--dark-grey);
  font-family: "Roboto-Medium";
  margin: 0;
}

.btnCancel {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.middleSection > p {
  text-align: start;
  color: var(--dark-grey);
  font-family: "Roboto-Regular";
  width: 90%;
}

.middleSection > p > span {
  text-align: start;
  color: var(--dark-grey);
  font-family: "Roboto-Medium";
  text-transform: capitalize;
}

.btnSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btnNo,
.btnYes,
.btnYesDisabled,
.btnRemove,
.btnAdd {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 0.7rem 1rem;
  margin: 1rem 1rem 1rem 0.5rem;
  border-radius: 4px;
  font-size: var(--popup-08rem);
  font-family: "Roboto-Medium";
  box-shadow: 0px 1px 8px var(--box-shadow);
  width: 5.6rem;
}

.largeBtnContainer{
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 0.7rem 1rem;
  margin: 1rem 1rem 1rem 2rem;
  border-radius: 4px;
  font-size: var(--popup-08rem);
  font-family: "Roboto-Medium";
  box-shadow: 0px 1px 8px var(--box-shadow);
  background-color: var(--mfg-red);
  color: var(--pure-white);
  border: 1px solid var(--mfg-red);
}

.btnRemove,
.btnAdd {
  padding: 0.7rem 1rem;
  margin: 1rem 0.5rem 1rem 0.5rem;
}

.btnNo,
.btnRemove {
  background-color: var(--pure-white);
  color: var(--btnText-grey);
  border: 0.1rem solid var(--border-grey);
}

.btnYes,
.btnAdd {
  background-color: var(--mfg-red);
  color: var(--pure-white);
  border: 1px solid var(--mfg-red);
}
.btnYesDisabled {
  background-color: var(--mfg-light-red);
  color: var(--pure-white);
  border: 1px solid var(--mfg-light-red);
  cursor: not-allowed;
}
.downloadPopup{
  margin: 10% auto;
  padding: 1rem;
  background-color: var(--pure-white);
  border-radius: 5px;
  width: 50%;
  position: relative;
}

.green {
  background-color: green;
  border: 1px solid green;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .popup {
    margin: 10% auto;
    width: 30%;
  }

}

@media (min-width: 600px) and (max-width: 768px) {
  .popup {
    margin: 25% auto;
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .popup {
    margin: 40% auto;
    width: 70%;
  }
}

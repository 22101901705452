@import url("../shared/Fonts/fonts.css");

:root {
  /* Color varibales */
  --pure-white: rgba(255, 255, 255, 1);
  --background-grey: rgba(102, 102, 102, 0.06);
  --grey-border: rgba(102, 102, 102, 0.12);
  --fileUpload-border: rgba(204, 204, 204, 1);
  --hightLight-grey: rgba(68, 68, 68, 1);
  --fileUploadText-grey: rgba(102, 102, 102, 1);
  --mfg-blue: rgba(0, 56, 117, 1);

  /* Font varibales */
  --popup-08rem: 0.8rem;
  --fileUpload-09rem: 0.9rem;
  --fileUpload-1rem: 1rem;
}

.popUpContainer {
  background-color: var(--pure-white);
  width: 80%;
  margin: 1.5rem auto;
  padding: 1rem;
  border-radius: 4px;
  position: relative;
  min-height: 14rem;
}

.dailyLogPopUpContainer {
  background-color: var(--pure-white);
  width: 35%;
  margin: 1.5rem auto;
  padding: 1rem;
  border-radius: 4px;
  min-height: 6rem;
}

.fileDetailsBox,
.uploadedDetailsContainer {
  display: flex;
  flex-direction: row;
  margin: 1rem 0rem 1rem 0;
}

.uploadedSection {
  min-height: 20rem;
}

.uploadedDetailsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0rem;
}

.uploadIconContainer {
  display: flex;
}

.uploadIconContainer > img {
  margin-right: 0.5rem;
}

.fileDetailsBox,
.statusContainer,
.uploadedDetailsContainer {
  border: 1px solid var(--fileUpload-border);
  border-radius: 4px;
  padding: 1rem;
}

.fileInfoContainer {
  flex: 1;
}

.uploadTopBox {
  margin: 0 0.5rem 0 0;
}

.iconContainer {
  margin: 0 0.5rem 0 0;
}

.topBox > p,
.bottomBox > p,
.invalidCount,
.uploadBottomBar > p,
.uploadTopBox > p {
  margin: 0;
  padding: 0;
}

.invalidText {
  margin: 0 0 0 0.2rem;
}

.uploadBottomBar > p,
.bottomBox > p {
  text-align: start;
}

.topBox,
.bottomBox > p,
.uploadBottomBar > p {
  display: flex;
  align-items: flex-start;
}

.topBox > p,
.invalidCount,
.uploadTopBox > p {
  font-family: "Roboto-Medium";
  color: var(--hightLight-grey);
}

.bottomBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.bottomBox > p,
.invalidText {
  font-size: var(--fileUpload-09rem);
  font-family: "Roboto-Regular";
  color: var(--fileUploadText-grey);
}

.bottomBox > button,
.hideValidationContainer > button {
  margin: 0;
  padding: 0;
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.bottomBox > button {
  font-size: var(--fileUpload-09rem);
  font-weight: 500;
  font-family: "Roboto-Medium";
  color: var(--mfg-blue);
  letter-spacing: 1px;
}

.statusContainer {
  display: flex;
  flex-direction: column;
}

.statusValueContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.successStatusValueContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.successStatusValueContainer > div > img {
  width: 1.8rem;
  height: 1.8rem;
  margin: 0 0.5rem 0 0 ;
}
.statusIcon{
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.5rem 0 0 ;
}

.topStatusContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottomErrorContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 18vh;
  overflow-y: auto;
}

.errorHeader {
  display: flex;
}

.erroCol1,
.errorBodyCol1 {
  display: flex;
  width: 30%;
}

.erroCol2,
.errorBodyCol2 {
  display: flex;
  flex: 1;
}

.erroCol1 > p,
.erroCol2 > p {
  padding: 0;
  margin: 0.5rem;
  font-size: var(--fileUpload-1rem);
  color: var(--hightLight-grey);
  word-wrap: break-word;
  font-weight: 600;
}

.errorBodyCol1 > p,
.errorBodyCol2 > p {
  padding: 0;
  margin: 0.5rem;
  font-size: var(--fileUpload-09rem);
  word-wrap: break-word;
  color: var(--fileUploadText-grey);
}

.invalidCount {
  margin: 0 0.2rem;
}

.fileValidationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 15rem;
  max-height: 16rem;
}

.fileValidationContainer > p {
  color: var(--fileUploadText-grey);
}

.invoiceDetailsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.vatWarningText{
  font-size: x-small;
  color: teal;
  max-width: 14rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.vatWarningText:after {
  content:"";
  display: block;
  position: absolute;
  width: 20px;
  height: 15px;
  z-index: 1;
  pointer-events:initial;

}
.vatWarningText:hover:after{
  cursor: pointer;
}

.tooltip {
  display: none;
  position: absolute;
  top: 10;
  left: 10;
  padding: .7rem;
  border: solid 1px var(--global-border-color);
  border-radius: 4px;
  background-color: #fff;
  --webkit-box-shadow: 0 0 50px 0 rgba(0,0,0,0.3);
  opacity: 0;
  transition: opacity 0.5s ease;
}


.vatWarningText:hover + .tooltip {
  display: initial;
  transition: opacity 0.5s ease;
  opacity: 1;
}
.vatCnfrmContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.vatInfoContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.vatInfoContainer > div > div{
  width: 16rem;
}
.vatInfoContainer > div > input{
  margin-left: 0px;
}
.checkContainer {
  display: flex;
}

.checkContainer > input {
  margin: 0;
}

.invoiceStatusContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid var(--fileUpload-border);
  border-radius: 4px;
  padding: 1rem;
  margin: 1rem 0 1rem 0;
}
.datePicker{
  width: 16.4rem !important;
}
.topSection > p {
  text-align: start;
  font-size: var(--popup-08rem);
  color: var(--dark-grey);
  font-family: "Roboto-Medium";
  margin: 0;
}
:root {
  /* Color varibales */
  --pure-white: rgba(255, 255, 255, 1);
  --background-grey: rgba(102, 102, 102, 0.06);
  --grey-border: rgba(102, 102, 102, 0.12);
}

.popUpContainer {
  background-color: var(--pure-white);
  width: 18%;
  margin: 5% auto;
  padding: 1rem;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 100%;
}

.defaultImgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-grey);
  border: 4px solid var(--grey-border);
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

.hideImg {
  display: none;
}
.uploadedImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploadedImage {
  object-fit: contain;
  width: 70%;
  height: 100%;
  margin: 5px;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .popUpContainer {
    background-color: var(--pure-white);
    width: 30%;
    margin: 10% auto;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .popUpContainer {
    background-color: var(--pure-white);
    width: 40%;
    margin: 20% auto;
  }
}

@media only screen and (max-width: 600px) {
  .popUpContainer {
    background-color: var(--pure-white);
    width: 65%;
    margin: 30% auto;
  }
}

:root {
  /* Color varibales */
  --box-shadow: rgba(0, 0, 0, 0.08);
  --tableHeader-bgColor: rgba(51, 95, 144, 1);
  --pure-white: rgba(255, 255, 255, 1);
  --tableContent-color: #444444;
  --mgf-blue: #003875;
  --tableNthRowColor: rgba(240, 243, 247, 1);
  --table-border-color: #ccc;
  --activated-green: rgba(41, 219, 71, 1);
  --deactivated--red: rgba(255, 74, 74, 1);
  --recordCount--gray: rgba(102, 102, 102, 1);
  --table-border-radius: 0.25rem;
  --pagintionNumber-color: rgba(68, 68, 68, 1);

  /* Font size varibales */
  --global-table-07rem: 0.7rem;
  --global-table-08rem: 0.8rem;
  --global-table-09rem: 0.9rem;
}

@import url("../shared/Fonts/fonts.css");

/* Global table css */

.dataTable{
  display: flex;
  flex-direction: column;
  max-height: 68vh;
}
.tableContainer,
.siteTableContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1.5vh;
  border-top-left-radius: var(--table-border-radius);
  border-top-right-radius: var(--table-border-radius);
  box-shadow: 0px 1px 8px var(--box-shadow);
  overflow: auto;
}
.tableHead{
  position: sticky;
  top: 0;
}
.tableContent td {
  padding: 5px;
  padding-left: 0.9rem;
  margin-top: 0.5rem;
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  box-shadow: 0px 1px 8px var(--box-shadow);
}

.header,
.namedheader,
.smallNamedHeader {
  background-color: var(--tableHeader-bgColor);
  text-align: start;
  padding: 0.8rem 0.8rem 0.8rem 0rem;
  color: var(--pure-white);
  font-size: var(--global-table-08rem);
  font-weight: 400;
  font-family: "Roboto-Medium";
}

.moduleHeader {
  background-color: var(--tableHeader-bgColor);
  text-align: start;
  padding: 0.8rem 0.8rem 0.8rem 0rem;
  padding: 1.2rem 0.9rem;
  color: var(--pure-white);
  font-size: var(--global-table-08rem);
  font-weight: 400;
  font-family: "Roboto-Medium";
  min-width: 4rem;
}

.namedheader {
  min-width: 7.5rem;
}

.smallNamedHeader {
  min-width: 5rem;
}

.sortContainer,
.namedSortContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sortContainer {
  display: flex;
  justify-content: flex-start;
}

.namedSortContainer {
  display: flex;
  justify-content: left;
}

.btnSort {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.3rem;
}

.btnSort > p {
  margin: 0 0.5rem 0 0;
  padding: 0;
}

td {
  text-align: start;
  padding: 0.5rem 0.5rem 0.5rem 0.2rem;
  font-size: var(--global-table-08rem);
  font-weight: 400;
  font-family: "Roboto-Regular";
  letter-spacing: 0.02rem;
  color: var(--tableContent-color);
  text-transform: capitalize;
}
.amountCell{
  text-align: right;
  padding-right: 1rem;
}

.tdStyle{
  padding: 0rem;
}

tr {
  cursor: pointer;
  background-color: var(--pure-white);
}

tr:nth-child(even) {
  background-color: var(--tableNthRowColor);
}

.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  justify-content: space-between;
  background-color: var(--pure-white);
  padding: 0rem 1rem 0rem 1rem;
  box-shadow: 0px 1px 8px var(--box-shadow);
}

.pageCount > p {
  font-size: var(--global-table-09rem);
  font-family: "Roboto-Regular";
  letter-spacing: 0.01rem;
  color: rgba(68, 68, 68, 1);
}

.paginationBtn {
  width: 50%;
  list-style: none;
  display: flex;
  justify-content: right;
  font-family: "Roboto-Regular";
  font-size: var(--global-table-08rem);
}

.paginationBtn a {
  border: 1px solid var(--table-border-color);
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  cursor: pointer;
  color: var(--mgf-blue);
}

.paginationActive > a {
  color: var(--pure-white);
  background-color: var(--mgf-blue);
}

.paginationValues {
  color: var(--pagintionNumber-color);
  font-weight: 600;
}

.prev {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.next {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.paginationBtn {
  width: 50%;
  list-style: none;
  display: flex;
  justify-content: right;
}

.paginationBtn a {
  border: 1px solid var(--table-border-color);
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  cursor: pointer;
  color: var(--mgf-blue);
}

.paginationActive > a {
  color: var(--pure-white);
  background-color: var(--mgf-blue);
}

.paginationValues {
  color: var(--pagintionNumber-color);
  font-weight: 600;
}

.prev {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.next {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.btnOne,
.btnTwo {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  cursor: pointer;
  width: 2rem;
}

.iconOne {
  margin: 0 0.5rem 0 0;
}

.btnDeactivateUser,
.btnEditUser {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.contentRow {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.invoiceContentRow {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.actionRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.contentRow > p {
  display: flex;
  text-align: start;
  padding: 0;
  margin: 0;
}

.profileImagView {
  background-color: rgba(102, 102, 102, 0.06);
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.profileImg {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.userStatus,
.profileImagView,
.binIcon,
.editPenIcon {
  margin: 0 1rem 0 0;
}

.userStatus {
  background-color: var(--activated-green);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}

.modulesHeader {
  background-color: var(--tableHeader-bgColor);
  text-align: start;
  padding: 0.8rem;
  color: var(--pure-white);
  font-size: var(--global-table-08rem);
  font-family: "Roboto-Medium";
}

.modulesColumn {
  text-align: start;
  padding: 0.8rem 0.8rem 0.8rem 0rem;
  color: var(--tableContent-color);
  font-size: var(--global-table-08rem);
  font-family: "Roboto-Medium";
  font-weight: 500;
}

.modulesColumn,
.modulesHeader {
  width: 85%;
}

.moduleContentRow {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem 0 0;
  margin: 0;
}

.checkBoxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.checkBoxContainer > p {
  padding: 0 0 0 2px;
  margin: 0;
  color: var(--tableContent-color);
  font-family: "Roboto-Medium";
}

.siteTableContainer {
  margin-top: 10px;
  overflow-x: auto;
}

.siteTableContainer {
  width: 100%;
}

.siteTableContainer > table {
  margin: 0px;
  border-radius: 4px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

td > p {
  display: flex;
  text-align: start;
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  max-width: 12rem;
}

.btnRejectAction,
.btnConfirmAction{
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0.5rem 0.5rem 0;
  border-radius: 4px;
  font-size: var(--popup-08rem);
  font-family: "Roboto-Medium";
  box-shadow: 0px 1px 8px var(--box-shadow);
}
.btnConfirmAction{
    background-color: var(--mfg-red);
    color: var(--pure-white);
    border: 1px solid var(--mfg-red);
}
.btnRejectAction{
  background-color: var(--pure-white);
  color: var(--btnText-grey);
  border: 0.1rem solid var(--border-grey);
}
.inputContainer{
  max-width: 14rem ;
  display: flex;
}

@media only screen and (max-width: 963px) {
  .tableContainer,
  .siteTableContainer {
    /* overflow-x: auto;
    overflow-y: hidden; */
    max-height: 60vh; 
    overflow: auto;
  }
.tableHead{
  position: sticky;
  top: 0;
}

  .siteTableContainer {
    margin: 0rem;
  }
}


@media screen and (max-height: 680px)  {
  .dataTable{
    max-height: 60vh;
  }
}

@media screen and (min-height: 980px)  {
  .dataTable{
    max-height: 80vh;
  }
}

@media (min-width: 601px) and (max-width: 1280px) {
  .tableContainer .siteTableContainer {
    overflow-x: auto;
  }
}

@media only screen and (max-width: 600px) {
  .tableContainer {
    overflow-x: auto;
  }

  table {
    font-family: "Roboto-Medium";
    box-shadow: 0px 1px 8px var(--box-shadow);
  }

  .header {
    font-size: var(--global-table-07rem);
  }

  td {
    padding: 0.5rem;
    font-size: var(--global-table-07rem);
  }

  .paginationContainer {
    padding-left: 0.5rem;
  }

  .pageCount > p {
    font-size: var(--global-table-07rem);
  }

  .prev,
  .count1,
  .count2,
  .count3,
  .next {
    padding: 0.5rem;
    font-size: var(--global-table-07rem);
  }
}

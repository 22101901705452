@import url("../../shared/Fonts/fonts.css");
:root {
  /* Color varibales */
  --invoice-border-color: rgba(0, 0, 0, 0.08);
  --pure-white: rgba(255, 255, 255, 1);
  --background-color: rgba(250, 250, 250, 1);
  --grey: rgb(90, 87, 87);
  --mfg-red: rgba(226, 55, 63, 1);
  --mfg-blue: rgba(0, 56, 117, 1);
  --btnText-grey: rgba(102, 102, 102, 1);
  --border-grey: rgba(102, 102, 102, 0.671);

  /* Font size varibales */
  --invoice-09rem: 0.9rem;
  --invoice-1rem: 1rem;
  --invoice-17rem: 1.7rem;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background-color: var(--background-color);
}

.leftContainer {
  display: flex;
  flex-direction: column;
  flex: 0.20;
  width: 15%;
  height: 100%;
  background-color: var(--mgf-blue);
  padding: 0 1.5rem;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 1vw;
  width: 85%;
  margin: 0 1rem 0 1rem;
  background-color: var(--main-bg-color);
}

.leftFilterText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 2rem;
  font-family: "Roboto-Medium";
}

.btnClearFilters {
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  color: var(--pure-white);
  font-size: var(--dashboard-09rem);
  letter-spacing: 0.09rem;
  font-family: "Roboto-Medium";
}

.mainFilterText {
  text-transform: uppercase;
  color: var(--light-white);
  font-size: var(--dashboard-09rem);
  letter-spacing: 0.09rem;
}

.filterContainer {
  margin: 0px;
  padding: 0px;
}

.filterContainer > div > p {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: flex-start;
}

.dateContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.functionBtnContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.downloadCSVDiv{
  display: flex;
    justify-content: right;
}
.viewMainConatiner {
  background-color: var(--background-color);
  margin: 3rem 0 0 0;
  padding: 0 15rem 0 15rem;
}

.filtersContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0px;
}
.leftFilterContainer{
  display: flex;
  flex-wrap: wrap;
}
.btnBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.mainBtnContainer{
  display: flex;
  flex-direction: column-reverse;
  margin-left: 0.5rem;
}
.col {
  display: flex;
  flex-direction: row;
}

.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 40%;
}

.hiddenTag {
  text-transform: capitalize;
  color: var(--grey);
  font-family: "Roboto-Regular";
  margin-bottom: 0.5rem;
  display: flex;
  visibility: hidden;
}

.featureButton,
.featureButtonDisable,
.createBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2rem;
  width: 5rem;
  border: none;
  cursor: pointer;
  padding: 0.5rem 2rem;
  border-radius: 4px;
}

.featureButton {
  background-color: var(--mfg-blue);
}
.featureButtonDisable {
  cursor: not-allowed;
  background-color: rgba(0, 57, 117, 0.37);
}

.transactionContainer > p {
  color: var(--dark-grey);
  font-family: "Roboto-Medium";
  font-size: var(--invoice-1rem);
  padding: 0;
  margin: 1rem 0 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createBtn {
  background-color: var(--mfg-red);
}

.btnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btnContainer > p,
.createBtn > p {
  color: var(--pure-white);
}

.btnContainer > p {
  margin: 0 0.5rem 0 0;
  padding: 0;
}

.inputContainer {
  flex: 1;
}

.row1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.row3 {
  margin-bottom: 1rem;
}

.displayText {
  display: flex;
  text-transform: capitalize;
  color: var(--btnText-grey);
  font-size: var(--invoice-09rem);
  margin: 0.8rem 0 1rem 0;
  padding: 0;
  color: var(--dark-grey);
  font-family: "Roboto-Medium";
  margin: 0;
  padding: 0;
}
.datePickerStyle{
  min-width: 5rem;
  margin: 0 1rem 0 0;
}

.reportDatePickerStyle{
  margin: 0 0rem 0 1rem;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .mainContainer {
    display: flex;
    flex-direction: row;
  }

  .leftContainer {
    width: 30%;
  }

  .rightContainer {
    display: flex;
    flex-direction: column;
    width: 70%;
  }

  .viewMainConatiner {
    background-color: var(--background-color);
    margin: 2rem 1rem 0 1em;
    padding: 0 2rem 0 2em;
  }
  .functionBtnContainer{
    margin-top: 1rem;
  }
  .filterContainer {
    /* padding: 0.5rem; */
  }
  .dateContainer {
    display: block;
  }

  .leftFilterText {
    padding: 0.5rem;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .mainContainer {
    display: flex;
    flex-direction: row;
  }

  .leftContainer {
    width: 40%;
  }

  .rightContainer {
    display: flex;
    flex-direction: column;
    width: 60%;
  }

  .viewMainConatiner {
    background-color: var(--background-color);
    margin: 2rem 1rem 0 1em;
    padding: 0 2rem 0 2em;
  }
}

@media only screen and (max-width: 600px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    width: 100%;
    padding: 0 0 2rem 0;
  }

  .leftContainer {
    width: 100%;
    padding: 0rem;
    margin: 0rem;
  }

  .rightContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .viewMainConatiner {
    background-color: var(--background-color);
    margin: 2rem 1rem 0 1em;
    padding: 0 2rem 0 2em;
  }

  .topContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .filtersContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 1rem 0 0;
  }

  .buttonsContainer {
    display: flex;
    width: 100%;
  }

  .featureButton,
  .createBtn {
    width: 10rem;
  }

  .inputContainer {
    display: flex;
    flex-direction: column;
    justify-items: center;
    width: 100%;
  }

  .detailsContainer {
    display: flex;
  }

  .row1 {
    display: flex;
    flex-direction: column;
    margin: 0rem 0 0 0;
    width: 100%;
  }

  .displayText {
    margin: 0rem 1rem 0rem 0;
  }
  .filterContainer {
    padding: 0.5rem;
  }

  .leftFilterText {
    padding: 0.5rem;
  }
}

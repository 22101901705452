@import url("../../shared/Fonts/fonts.css");

:root {
  /* Color varibales */
  --main-bgColor: rgba(250, 250, 250, 1);
  --header-grey: rgba(136, 136, 136, 1);
  --profile-img-container-bg: rgba(248, 248, 248, 1);
  --dark-grey: rgba(68, 68, 68, 1);
  --pure-white: rgba(255, 255, 255, 1);
  --input-border-color: rgba(204, 204, 204, 1);
  --mfg-blue: rgba(0, 56, 117, 1);
  --mfg-red: rgba(226, 55, 63, 1);
  --box-shadow: rgba(0, 0, 0, 0.08);
  --divider-grey: rgba(208, 208, 208, 1);

  /* Font size varibales */
  --addModule-09rem: 0.9rem;
  --addModule-1rem: 1rem;
  --addModule-17rem: 1.7rem;
}
.vatContainer {
  display: flex;
  flex-direction: column;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
}
.headerContainer > p {
  color: var(--dark-grey);
  font-family: "Roboto-Medium";
  font-size: var(--addModule-1rem);
}
.headerContainer > button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.deleteContainer{
  display: flex;
  justify-content: end;
}

.subContainer{
  width: 100%;
}

.vatBlock{
  display: flex;
  flex-direction: column;
  background-color: aliceblue;
  justify-content: space-between;
  background-color: var(--pure-white);
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 1rem 1rem 1rem;
  margin: 0 0 2rem 0;
  box-shadow: 0px 1px 8px var(--box-shadow);
}
.fieldBlock{
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

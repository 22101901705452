.loaderStyles {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 25;
}

.loaderSVG {
  width: 10rem;
  height: 8rem;
  margin: 2rem;
}

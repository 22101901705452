@import url("../shared/Fonts/fonts.css");

:root {
  /* Color varibales */
  --dark-grey: rgba(68, 68, 68, 1);
  --btnText-grey: rgba(102, 102, 102, 1);
  --border-grey: rgba(153, 153, 153, 0.363);
  --pure-white: rgba(255, 255, 255, 1);
  --mfg-light-red: rgba(255, 74, 74, 0.315);

  /* Font size varibales */
  --popup-08rem: 0.8rem;
  --popup-12rem: 1rem;
}

.overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 20;
}

.popup {
  margin: 2% auto;
  padding: 1rem;
  background-color: var(--pure-white);
  border-radius: 5px;
  width: 50%;
  position: relative;
}

.topSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.topSection > p {
  text-align: start;
  font-size: var(--popup-12rem);
  color: var(--dark-grey);
  font-family: "Roboto-Medium";
  margin: 0;
}

.btnCancel {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.middleSection {
  width: 100%;
}

.middleSection > p {
  text-align: start;
  color: var(--dark-grey);
  font-family: "Roboto-Regular";
}

.listingSection {
  overflow: scroll;
  max-height: 3rem;
  border: solid 1px var(--global-border-color);
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.listingSection > p {
  text-align: start;
  color: var(--dark-grey);
  font-family: "Roboto-Regular";
  padding: 0.2rem;
  margin: 0rem;
}
.moduleSupplierMainSection {
  overflow: scroll;
  max-height: 10rem;
  border: solid 1px var(--global-border-color);
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}


.errorHeader {
  display: flex;
}

.erroCol1,
.errorBodyCol1 {
  display: flex;
  width: 30%;
}

.erroCol2,
.errorBodyCol2 {
  display: flex;
  flex: 1;
}

.erroCol1 > p,
.erroCol2 > p {
  padding: 0;
  margin: 0.5rem;
  font-size: var(--fileUpload-1rem);
  color: var(--hightLight-grey);
  word-wrap: break-word;
  font-weight: 600;
}

.errorBodyCol1 > p,
.errorBodyCol2 > p {
  padding: 0;
  margin: 0.5rem;
  font-size: var(--fileUpload-09rem);
  word-wrap: break-word;
  color: var(--fileUploadText-grey);
}

.selectionBox {
  display: flex;
  align-items: flex-start;
  margin: 0 1rem 1rem 0;
}

.selectionBox > select{
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: var(--inputs-09rem);
  font-family: "Roboto-Regular";
  border: 1px solid var(--global-border-color);
  padding: 0rem 1rem 0rem 1rem;
  margin: 0;
  border-radius: 2px;
  width: 17rem;
  height: 2.4rem;
  position: relative;
  color: var(--btnText-grey);
  background: url("../assets/icons/arrowDownFilled.svg") no-repeat;
  background-position: 95% center;
  border-radius: 4px;
  cursor: pointer;
}
.invoiceSelectionBox > select {
  width: 17rem;
}

.selectionBox > select:focus {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("../assets/icons/arrowUpFilled.svg") no-repeat;
  background-position: 95% center;
}

.middleSection > p > span {
  text-align: start;
  color: var(--dark-grey);
  font-family: "Roboto-Medium";
  text-transform: capitalize;
}
.checkContainer {
  display: flex;
  margin: 1rem 0 0 0;
}

.checkContainer > input {
  margin: 0;
}
.invalidText {
  margin: 0 0 0 0.2rem;
  font-size: var(--fileUpload-09rem);
  font-family: "Roboto-Regular";
  color: var(--fileUploadText-grey);
}

.btnSection {
  display: flex;
  justify-content: flex-end;
}

.btnNo,
.btnYes,
.btnYesDisabled,
.btnRemove,
.btnAdd {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 0.7rem 2rem;
  margin: 1rem 1rem 1rem 0;
  border-radius: 4px;
  font-size: var(--popup-08rem);
  font-family: "Roboto-Medium";
  box-shadow: 0px 1px 8px var(--box-shadow);
}

.btnRemove,
.btnAdd {
  padding: 0.7rem 1rem;
  margin: 1rem 0.5rem 1rem 0.5rem;
}

.btnNo,
.btnRemove {
  background-color: var(--pure-white);
  color: var(--btnText-grey);
  border: 0.1rem solid var(--border-grey);
}

.btnYes,
.btnAdd {
  background-color: var(--mfg-red);
  color: var(--pure-white);
  border: 1px solid var(--mfg-red);
}
.btnYesDisabled {
  background-color: var(--mfg-light-red);
  color: var(--pure-white);
  border: 1px solid var(--mfg-light-red);
  cursor: not-allowed;
}
.downloadPopup {
  margin: 10% auto;
  padding: 1rem;
  background-color: var(--pure-white);
  border-radius: 5px;
  width: 50%;
  position: relative;
}
@media (min-width: 768px) and (max-width: 1280px) {
  .popup {
    margin: 10% auto;
    width: 50%;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .popup {
    margin: 15% auto;
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .popup {
    margin: 25% auto;
    width: 70%;
  }
}

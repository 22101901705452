.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App {
  height: 100vh;
}
:root {
  --dark-blue: #003875;
  --dark-gray: #444444;
  --light-gray: #b8b8b8;
  --white: #ffffff;
  --black: #000000;
  --mfg-red: #e2373f;
  --font-14: 0.875rem;
  --background-color: rgba(250, 250, 250, 1);
}
.lable {
  display: inline-block;
  margin-bottom: 0.5rem;
}
::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
}
::-webkit-scrollbar-thumb {
  background: var(--dark-blue);
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px var(--primary-color);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
body {
  height: auto;
  overflow: hidden;
  background-color: var(--background-color);
}
html body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url("../../shared/Fonts/fonts.css");

:root {
  --main-bgColor: rgba(250, 250, 250, 1);
  --light-gray: rgba(136, 136, 136, 1);
  --dark-grey: rgba(68, 68, 68, 1);
  --pure-white: rgba(255, 255, 255, 1);
  --border-color: rgba(204, 204, 204, 1);
  --mfg-blue: rgba(0, 56, 117, 1);
  --mfg-red: rgba(226, 55, 63, 1);
  --box-shadow: rgba(0, 0, 0, 0.2);
  --form-label: rgba(102, 102, 102, 1);
  --hr-line: rgba(208, 208, 208, 1);
  --binBtn-bg: rgba(226, 55, 63, 0);

  --base-08rem: 0.9rem;
  --base-1rem: 1rem;
  --base-12rem: 1.2rem;
  --border-radius: 0.25rem;
}

.mainContainer {
  background-color: var(--main-bgColor);
  margin: 2rem;
  display: flex;
}

.headerContainer,
.displayHeaderContainer {
  display: flex;
  flex-direction: column;
}

.displayHeaderContainer {
  display: flex;
  flex-direction: row;
  padding-right: 0rem;
}

.contentContainer {
  display: flex;
  flex-direction: row;
}

.contentNavContainer {
  width: 25%;
  position: sticky;
}

.contentNavigation {
  display: flex;
  justify-content: center;
  margin-top: 2.3rem;
  position: sticky;
}

.contentNavigation > ul {
  border-right: 1px solid var(--hr-line);
  margin-right: 1rem;
  padding: 0rem;
}

.contentNavigation > ul > li {
  list-style-type: none;
  padding-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.inactiveText > a {
  color: var(--light-gray);
  text-decoration: none;
  font-family: "Roboto-Regular";
  font-size: var(--base-1rem);
}

.activeText > a {
  font-family: "Roboto-Medium";
  text-decoration: none;
  color: var(--dark-grey);
  font-size: var(--base-1rem);
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.headerContainer > button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.btnCancel,
.btnDone {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 0.5rem 1.8rem;
  margin: 1rem 1rem 1rem 0;
  border-radius: 4px;
  font-size: var(--font-small);
  font-family: "Roboto-Medium";
  box-shadow: 0px 1px 8px var(--box-shadow);
}

.btnCancel {
  background-color: var(--pure-white);
  color: var(--btnText-grey);
  border: 1px solid var(--header-grey);
}

.btnDone {
  background-color: var(--mfg-red);
  color: var(--pure-white);
  border: 1px solid var(--mfg-red);
}

.content {
  display: flex;
  flex-direction: column;
}

button {
  cursor: pointer;
}

.searchBox > input,
.inputBox > input,
.mandatoryField > input {
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  width: 95%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: var(--base-08rem);
  font-family: "Roboto-Medium";
  color: var(--dark-grey);
}

.searchBox,
.inputBox,
.displayBox,
.mandatoryField {
  display: flex;
  align-items: center;
  background-color: var(--pure-white);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: 0.5rem;
  margin: 0;
  width: 14.9rem;
  height: 1.4rem;
}

.mandatoryField {
  border: 1px solid var(--mfg-red);
}

.errorMessageShow {
  margin-top: 0.5rem;
  color: var(--mfg-red);
  display: flex;
  font-family: "Roboto-Regular";
  font-size: var(--base-08rem);
}

.errorMessage {
  display: none;
}

.dateContainer {
  width: 16rem;
  height: 5.25rem;
  padding: 0rem;
}

.displayBox {
  border: none;
  margin-left: -0.56rem;
  padding-top: 0rem;
  margin-top: -0.3rem;
  color: var(--dark-grey);
  font-family: "Roboto-Medium";
  font-size: var(--base-08rem);
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 100%;
}
.displayBox:hover{
  overflow: visible;
    white-space: normal;
    word-wrap: break-word;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.searchIcon {
  margin-right: 0.5rem;
}

.selectionBox,
.mandatoryField {
  border: none;
  width: 17.2rem;
  margin: 0 1rem 0 0;
  padding-left: 0rem;
  padding-right: 0rem;
}

.selectionBox > select,
.mandatoryField > select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid var(--border-color);
  padding: 0rem 0.5rem 0rem 0.5rem;
  margin: 0;
  border-radius: 2px;
  width: 17.2rem;
  height: 2.5rem;
  position: relative;
  color: var(--dark-grey);
  font-family: "Roboto-Medium";
  font-size: var(--base-08rem);
  background: url("../../assets/icons/arrowDownFilled.svg") no-repeat;
  background-position: 95% center;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.mandatoryField > select {
  border: 1px solid var(--mfg-red);
  width: 17.2rem;
}

.selectionBox > select:focus {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-position: 95% center;
}

.selectedValueHide {
  display: none;
}

.siteForm,
.companyForm,
.supplierForm {
  display: none;
}

.cmForm {
  flex-direction: column;
}

.siteForm,
.cmForm,
.companyForm,
.supplierForm,
.addForm,
.settingsForm,
.moduleForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: var(--pure-white);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  justify-content: space-between;
  padding: 1rem;
  box-shadow: 0px 1px 8px var(--box-shadow);
}

.addForm {
  width: 100%;
  box-shadow: none;
}

.moduleForm {
  justify-content: start;
}

.col {
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem 0 0;
}

.addForm {
  padding: 0rem;
}

.assignModule,
.assignModuleContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.assignModuleContainer{
  justify-content: space-between;
}

.assignModule {
  width: 92%;
}

.cmForm,
.moduleForm {
  margin-top: 0.5rem;
}

.addForm {
  border: none;
}

.deleteBtnCMContainer,
.deleteModuleAssign {
  width: 100%;
  display: flex;
  justify-content: right;
}

.deleteBtnModule {
  width: 20%;
}

.inputContainer{
  display: flex;
  align-items: center;
  padding-top: 2.5rem;
  padding-left: 1rem;
}

.inputPreviliageContainer{
  display: flex;
  align-items: center;
  padding-top: 2.5rem;
}

.checkboxInput {
  display: flex;
}
.displayCheckboxContainer{
  display: flex;
  width: 18rem;
}

.exportButtons{
  align-items: center;
  color: var(--btnText-grey);
  font-size: var(--global-09rem);
  font-family: "Roboto-Regular";
  margin: 0.8rem 1rem 0.8rem 0;
  padding: 0;
  display: flex;
}
.deleteBtnModuleHide {
  display: none;
}

.deleteModuleAssign {
  padding-top: 2rem;
  margin-right: 0.5rem;
}

.companyForm {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0 1rem 0 2rem;
}

.settingsForm{
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0 1rem 0 2rem; 
}

.formFieldContainer {
  display: flex;
  flex-direction: column;
  width: 13rem;
  margin: 0 1rem 0 0;
}

.formFieldContainer > p {
  text-align: left;
  color: var(--form-label);
  font-size: var(--base-08rem);
  font-family: "Roboto-Regular";
}

.formDataContainer {
  display: flex;
  flex-direction: column;
  width: 17rem;
  margin: 0 1rem 0 0;
}

.dateTitle {
  text-align: left;
  color: var(--form-label);
  font-size: var(--base-08rem);
  font-family: "Roboto-Regular";
  margin: 0.8rem 1rem 0.8rem 0;
}

.errorMessageDate {
  color: var(--mfg-red);
  text-align: left;
  font-size: var(--base-08rem);
  font-family: "Roboto-Regular";
  margin-top: 0.3rem;
}

.navigateContainer,
.displayNavigateContainer {
  display: flex;
  align-items: center;
  width: 50%;
}

.navigateContainer > p,
.displayNavigateContainer > p {
  font-size: var(--base-1rem);
  font-family: "Roboto-Medium";
  color: var(--light-gray);
  cursor: pointer;
}

.navigateContainer > button,
.displayNavigateContainer > button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.displayNavigateContainer {
  width: 50%;
  display: flex;
}

.navigateContainer > button,
.siteNavigation > button,
.displayNavigateContainer > button {
  display: flex;
}

.siteNavContainer,
.cmNavContainer {
  display: flex;
  width: 100%;
}

.siteNavigation,
.cmNavigation {
  display: flex;
  justify-content: space-between;
  width: 99%;
}

.siteNavigation,
.cmNavigation > p {
  font-size: var(--base-1rem);
  font-family: "Roboto-Medium";
  color: var(--dark-grey);
}

.backArrow {
  object-fit: contain;
  margin-right: 1rem;
}

.upArrow {
  object-fit: contain;
}

.siteNavigation > button,
.cmNavigation > button {
  background: none;
  color: inherit;
  border: none;
  align-items: center;
}

hr {
  margin-top: 2rem;
  margin-left: 0rem;
  width: 100%;
  color: var(--hr-line);
}

.cmMainForm {
  display: flex;
  flex-direction: column;
}

.cmBtnContainer,
.btnContainer {
  display: flex;
  width: 100%;
  justify-content: right;
}

.btnContainer {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.binBtn > button,
.addBtn > button {
  width: 4.8rem;
  height: 2.2rem;
  margin: 0.5rem;
  border-radius: var(--border-radius);
}

.deleteBtnCM {
  width: 4.8rem;
  height: 2.2rem;
  margin: 0.5rem;
  border-radius: var(--border-radius);
  display: block;
  background-color: var(--binBtn-bg);
  border: 1px solid var(--border-color);
}

.deleteBtnCMHide {
  display: none;
}

.binBtn > button {
  background-color: var(--binBtn-bg);
  border: 1px solid var(--border-color);
}

.binBtn > button > img {
  margin-top: 0.1rem;
  height: 1.2rem;
  width: 1rem;
}

.addBtn > button {
  background-color: var(--mfg-red);
  border: none;
}

.cancelBtn > button,
.doneBtn > button {
  width: 6rem;
  height: 2.2rem;
  margin: 0.5rem;
  border-radius: var(--border-radius);
}

.cancelBtn > button {
  border: 1px solid var(--border-color);
  color: var(--form-label);
}

.doneBtn > button {
  background-color: var(--mfg-red);
  border: none;
  color: var(--pure-white);
}

.multiDropdown{
  background-color: rebeccapurple;

}

@media (min-width: 768px) and (max-width: 1280px) {
  .mainContainer {
    flex-direction: column;
  }
}

@media only screen and (max-width: 963px) {
  .mainContainer {
    margin: 2rem;
    flex-direction: column;
  }

  .assignModule {
    flex-direction: column;
  }

  .supplierForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 600px) {
  .assignModuleContainer {
    width: auto;
  }

  .deleteModuleAssign {
    justify-content: left;
  }

  .contentNavigation {
    margin-top: 0rem;
  }

  .contentNavContainer {
    width: 50%;
  }

  .siteNavigation {
    width: 100%;
  }

  .siteForm,
  .cmForm,
  .addForm,
  .moduleForm,
  .supplierForm ,
  .settingsForm,
  .companyForm{
    justify-content: space-around;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .headerContainer,
  .displayHeaderContainer {
    padding-left: 0rem;
  }

  .assignModuleContainer,
  .assignModule {
    flex-direction: column;
  }
}

@media only screen and (max-width: 460px) {
  .companyForm{
    padding: 0rem;
  }
}

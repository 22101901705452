.functionBtnContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 30px;
}
.leftFilterContainer{
  display: flex;
  flex-wrap: wrap;
}

.functionBtnItemContainer{
    display: flex;
    flex-wrap: wrap;
    flex: 3 1;
    justify-content: space-between;
    margin-left: 40px;
    margin-right: 1rem;
}
.stationHeaderContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.statusFieldContainer {
    display: flex;
    flex-direction: column;
    width: 13rem;
  }

.statusFieldContainer > p {
    text-align: left;
    color: var(--form-label);
    font-size: var(--base-08rem);
    font-family: "Roboto-Regular";
  }

  .searchStatusDiv{
    display: flex;
  }

.mainContainer {
    display: flex;
    padding-bottom: 0.5rem;
    justify-content: end;
}

.topContainer {
    display: flex;
    padding-bottom: 0.5rem;
    width: 100%;
}

.popUpContainer {
    background-color: var(--pure-white);
    width: 50%;
    margin: 1.5rem auto;
    padding: 1rem;
    border-radius: 4px;
    position: relative;
    min-height: 14rem;
}

.popupDetailsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.filterBtnContainer{
    display: flex;
    flex-wrap: wrap;
    margin-left: 22%;
}

.datePickerContainer{
  min-width: 35%;
  display: flex;
  flex-direction: row; 
}

.disabledRow > tr > td{
    color: #777777;
}
.disabledRow > tr {
    background-color: rgb(240, 240, 240);
    cursor: not-allowed;
}
.row > tr  {
    border-bottom: 0.5px solid rgb(230, 230, 230);
}

.tableContainer {
    overflow-y: scroll;
}

.popUpBody {
    padding: 0 0 2rem 0;
}

.filterContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.mainFilterContainer {
    display: flex;
    justify-content: end;
    margin-right: 2rem;
    margin-bottom: 0.5rem;
}

.tableRowCell {
    width: 4rem;
}

.searchBoxContainer {
    display: flex;
    flex-wrap: wrap;
  }
  .subContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  @media only screen and (max-width: 963px) {
    .subContainer {
      width: 85%;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .subContainer {
      width: 100%;
    }
  }
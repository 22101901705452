@import url("../../shared/Fonts/fonts.css");

:root {
  /* Color varibales */
  --box-shadow: rgba(0, 0, 0, 0.08);
  --tableHeader-bgColor: rgba(51, 95, 144, 1);
  --pure-white: rgba(255, 255, 255, 1);
  --tableContent-color: #444444;
  --mgf-blue: #003875;
  --tableNthRowColor: rgba(240, 243, 247, 1);
  --userManagement-border-color: rgba(102, 102, 102, 0.12);
  --activated-green: rgba(41, 219, 71, 1);
  --deactivated--red: rgba(255, 74, 74, 1);

  /* Font size varibales */
  --userTable-07rem: 0.7rem;
  --userTable-08rem: 0.8rem;
  --userTable-09rem: 0.9rem;
  --userTable-3rem: 3rem;
}

.moduleHeader,
.namedHeader {
  background-color: var(--tableHeader-bgColor);
  text-align: start;
  padding: 0.8rem 0.8rem 0.8rem 0rem;
  color: var(--pure-white);
  font-weight: 400;
  font-size: var(--userTable-09rem);
  font-family: "Roboto-Medium";
  min-width: 8rem;
}

.namedHeader {
  text-align: center;
}

.btnDeactivateUser,
.btnEditUser,
.btnEditUserCustom {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.btnEditUserCustom {
  margin: 0 0 0 2.5rem;
}

.btnDeactivateUser {
  margin-left: 1rem;
}

.contentRow,
.contentRowNamed,
.actionRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--tableContent-color);
  font-family: "Roboto-Regular";
}

.actionRow {
  display: flex;
  justify-content: center;
}

.contentRowNamed {
  justify-content: flex-start;
  font-family: "Roboto-Medium";
}

.contentRow > p,
td > p {
  display: flex;
  text-align: start;
  padding: 0;
  margin: 0;
  text-align: start;
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  max-width: 12rem;
}
.contentRow > p {
  text-transform: capitalize;
}

.tdLowerCase {
  text-transform: lowercase;
}

.avatar {
  vertical-align: middle;
  min-width: 1.5rem;
  height: 2rem;
  border-radius: 100%;
  cursor: pointer;
  text-transform: uppercase;
}
.avatar > img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
}

.avatarLetters {
  border: 1px solid #6666661f;
  background: #6666660f;
  color: var(--dark-gray);
  font-size: var(--userTable-09rem);
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 0 0;
  width: 1.5rem;
  height: 1.5rem;
}

.profileAvatar {
  vertical-align: middle;
  width: 8rem;
  height: 8rem;
  border-radius: 100%;
  cursor: pointer;
  text-transform: uppercase;
}

.avatarChars {
  font-size: var(--userTable-3rem);
}

.tdCapitalized {
  text-transform: capitalize;
}

.profileImageView {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(102, 102, 102, 0.06);
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid var(--userManagement-border-color);
}

.userStatus,
.profileImageView,
.binIcon,
.editPenIcon {
  margin: 0 1rem 0 0;
}

.userActive,
.userDeactive {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 1rem 0 0;
}

.userActive {
  background-color: var(--activated-green);
}

.userDeactive {
  background-color: var(--deactivated--red);
}

.modulesHeader {
  background-color: var(--tableHeader-bgColor);
  text-align: start;
  padding: 0.8rem 0.8rem 0.8rem 0.2rem;
  color: var(--pure-white);
  font-size: var(--userTable-08rem);
  font-family: "Roboto-Regular";
  letter-spacing: 0.05rem;
}

.modulesColumn {
  text-align: start;
  padding: 0.8rem 0.8rem 0.8rem 0.2rem;
  color: var(--tableContent-color);
  font-size: var(--userTable-08rem);
  font-family: "Roboto-Medium";
  font-weight: 500;
}

.modulesColumn,
.modulesHeader {
  width: 82%;
}

.moduleContentRow {
  display: flex;
  padding: 0 0.5rem 0 0;
  margin: 0;
}

.checkBoxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 0 0;
}

.checkBoxContainer > p {
  padding: 0 0 0 2px;
  margin: 0;
  color: var(--tableContent-color);
  font-family: "Roboto-Medium";
}

@media (min-width: 1280px) and (max-width: 1450px) {
  .profileImageView {
    width: 2.5rem;
    height: 2rem;
  }
}

@media (min-width: 601px) and (max-width: 1280px) {
  .tableContainer {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .profileImageView {
    width: 2.5rem;
    height: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .tableContainer {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .moduleHeader,
  .namedHeader {
    text-align: start;
    padding: 0.8rem 0rem 0.8rem 0.5rem;
    margin: 0.5rem 0rem 0.5rem 0.5rem;
    font-size: var(--userTable-07rem);
  }

  .namedHeader {
    text-align: center;
  }

  .contentRow,
  .contentRowNamed {
    margin-left: 0.5rem;
    font-size: var(--userTable-07rem);
  }

  .contentRowText {
    display: flex;
    text-align: start;
    padding: 0;
    margin: 0;
    font-size: var(--userTable-07rem);
  }

  .profileImageView {
    width: 2rem;
    height: 2rem;
  }

  .profileImg {
    object-fit: contain;
  }

  .binIcon {
    margin: 0 1rem 0 1.2rem;
  }
}

@import url("../../shared/Fonts/fonts.css");

:root {
  /* Color varibales */
  --userManagement-border-color: rgba(0, 0, 0, 0.08);
  --pure-white: rgba(255, 255, 255, 1);
  --background-color: rgba(250, 250, 250, 1);
  --grey: rgb(90, 87, 87);
  --mfg-red: rgba(226, 55, 63, 1);
  --btnText-grey: rgba(102, 102, 102, 1);
  --border-grey: rgba(102, 102, 102, 0.671);

  /* Font size varibales */
  --userManagement-09rem: 0.9rem;
  --userManagement-1rem: 1rem;
  --userManagement-17rem: 1.7rem;
}

.mainContainer {
  background-color: var(--background-color);
  margin: 0;
  padding: 0 5rem 0 5rem;
}

.filtersContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 0px;
}
.leftFilterContainer{
  display: flex;
}

.filterText {
  text-transform: capitalize;
  color: var(--btnText-greyy);
  font-size: var(--userManagement-09rem);
  font-family: "Roboto-Regular";
  margin-bottom: 0.5rem;
  display: flex;
}

.hiddenTag {
  text-transform: capitalize;
  color: var(--grey);
  margin-bottom: 0.4rem;
  display: flex;
  visibility: hidden;
}

.passwordIcon {
  object-fit: contain;
  width: 20px;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .mainContainer {
    margin: 0;
    padding: 0 2rem 0 2rem;
  }

}

@media (min-width: 600px) and (max-width: 768px) {
  .mainContainer {
    margin: 0;
    padding: 0 1.5rem 0 1.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .mainContainer {
    margin: 0;
    padding: 0 1rem 0 1rem;
  }

  .addContainer > p {
    margin-top: 0;
  }

  .addContainer > button {
    height: 2rem;
    padding: 0.5rem 2rem;
    margin-bottom: 1rem;
  }
}

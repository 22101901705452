@import url("../../shared/Fonts/fonts.css");

:root {
  --main-bg-color: rgba(250, 250, 250, 1);
  --main-border-color: rgba(0, 0, 0, 0.12);
  --pure-white: rgba(255, 255, 255, 1);
  --mfg-blue: rgba(0, 56, 117, 1);
  --mfg-red: rgba(226, 55, 63, 1);
  --green-status: rgba(41, 219, 71, 1);
  --black-textColor: rgba(68, 68, 68, 1);
  --box-shadow: rgba(0, 0, 0, 0.08);
  --button-shadow: rgba(0, 0, 0, 0.2);
  --gray-status: rgba(153, 153, 153, 1);
  --inactive-status: rgba(255, 74, 74, 1);
  --search-placeholder: rgba(170, 170, 170, 1);
  --record-count: rgba(102, 102, 102, 1);
  --tableEven-background: rgba(240, 243, 247, 1);

  --base-08rem: 0.8rem;
  --base-1rem: 1rem;
  --base-12rem: 1.2rem;

  --letter-spacing: 0.09rem;
  --border-radius: 0.25rem;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  padding-right: 1rem;
  height: 100%;
}

.headerContainer {
  width: 100%;
  display: flex;
}

.siteHeader {
  grid-column: 2;
  grid-row: 1;
  width: 100%;
  min-height: 5rem;
  /* margin-bottom: 1rem; */
}

.leftContainer {
  width: 20%;
  margin-top: 5rem;
}

.rightContainer {
width: 80%;
display: flex;
flex-direction: column;
}

.listSpace {
  padding: 0rem;
  margin: 0rem;
  width: 1.7rem;
  display: inline-block;
}

.listSpaceActive {
  padding: 0rem;
  margin: 0rem;
  width: 1.33rem;
  display: inline-block;
}

.siteHeaderLeft > ul {
  padding-left: 0rem;
}

.siteHeaderLeft > ul > li {
  width: 13.75rem;
  padding-left: 0.4rem;
}

.filterContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items:end;
}

.subMenuContainer {
  display: flex;
  width: 100%;
}

.subMenuSpan{
  padding-right: 1rem;
}

.searchContainer {
  display: flex;
  justify-content: left;
  flex-direction: column;
}

.filterText {
  display: flex;
  justify-content: left;
  font-family: "Roboto-Regular";
  font-size: var(--base-08rem);
  color: var(--record-count);
}

.searchBox {
  display: flex;
  align-items: center;
  background-color: var(--pure-white);
  box-shadow: 0px 0px 6px var(--box-shadow);
  border-radius: var(--border-radius);
  margin: 0;
  float: left;
  height: 2.4rem;
  width: 18.7rem;
}

.searchBox > img {
  padding: 0.5rem;
  padding-right: 0.7rem;
}

.searchBox > input {
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: var(--base-08rem);
  letter-spacing: var(--letter-spacing);
  font-family: "Roboto-Regular";
  color: var(--search-placeholder);
  width: 95%;
}

.btnContainer {
  display: flex;
  margin-top: auto;
  float: right;
}

.uploadContainer > button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 6.3rem;
  height: 2.4rem;
  background-color: var(--mfg-blue);
  border-radius: var(--border-radius);
  opacity: 1;
  color: var(--pure-white);
  letter-spacing: var(--letter-spacing);
  justify-content: center;
  margin-right: 0.7rem;
  font-family: "Roboto-Medium";
  font-size: var(--base-08rem);
}

.filterBtn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.3rem;
}

.filterBtn > p {
  margin: 0 0.5rem 0 0;
  padding: 0;
}

.filterBtn > img {
  margin-bottom: 0.1rem;
}

.uploadContainer > button > img {
  width: 0.5rem;
  height: 0.8rem;
  opacity: 1;
  margin-left: 0.3rem;
  border: none;
}
.addContainer {
  display: flex;
  flex-direction: column-reverse;
}
.addContainer > button,
.addSupplierContainer > button {
  background-color: var(--mfg-red);
  box-shadow: 0px, 0px, 6px var(--button-shadow);
  border-radius: var(--border-radius);
  margin-left: 0.62rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2rem;
  width: 5rem;
  border: none;
  cursor: pointer;
}

.addSupplierContainer > button {
  margin-left: 0rem;
}

.siteTable {
  box-shadow: 0px 0px 6px var(--box-shadow);
}

.tableContent td {
  padding: 0rem;
  padding-left: 0.9rem;
  width: 2.5rem;
}

.tableContent :first-child {
  font-family: "Roboto-Medium";
}

.userStatusActive,
.userStatusInactive,
.userStatusComingSoon {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;
}

.userStatusActive {
  background-color: var(--activated-green);
}

.userStatusInactive {
  background-color: var(--inactive-status);
}

.userStatusComingSoon {
  background-color: var(--gray-status);
}

.siteTableHead {
  height: 3rem;
}

.theadHeader > th {
  background-color: var(--tableHeader-bgColor);
  text-align: start;
  padding: 0.8rem 0.8rem 0.8rem 0rem;
  color: var(--pure-white);
  font-size: var(--base-08rem);
  font-weight: 400;
  font-family: "Roboto-Medium";
}

.theadHeader > .smallField {
  min-width: 7rem;
}

.theadHeader > .largeField {
  min-width: 15.5rem;
}

.actionIcon {
  justify-content: center;
}

.siteTableBody > tr {
  height: 0rem;
}

.actionIcon > li {
  text-decoration: none;
  display: inline-block;
  margin-right: 15%;
}

.recordCount {
  color: var(--record-count);
  font-family: "Roboto-Regular";
  font-size: var(--base-08rem);
}

.baseNavContainer {
  margin: 0.5rem 2rem 0 2rem;
}

.baseNavContainer > ul {
  list-style-type: none;
  text-align: left;
  padding-inline-start: 0;
  background-color: var(--pure-white);
  border-radius: var(--border-radius);
  margin: 0rem;
  overflow: hidden;
  padding-bottom: 1rem;
}

.baseNavContainer > ul > li {
  /* width: 13rem; */
  /* height: 2.5rem; */
  padding: 1rem 1rem 0 0;
  /* vertical-align: middle;
  text-align: start; */
  color: var(--gray-status);
  font-size: var(--base-1rem);
  font-family: "Roboto-Regular";
  cursor: pointer;
  display: flex;
  word-wrap: break-word;
  /* justify-content: center; */
}

.baseNavContainer > ul > .navHeading {
  color: var(--mfg-blue);
}

.navHeading {
  border-left: 6px solid var(--mfg-blue);
  color: var(--mfg-blue);
  border-radius: var(--border-radius);
  font-family: "Roboto-Medium";
  font-size: var(--base-1rem);
}

.featureButtonDisable {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2rem;
  width: 5rem;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 4px;
  cursor: not-allowed;
  background-color: rgba(0, 57, 117, 0.37);
}
.featureButton {
  background-color: var(--mfg-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2rem;
  width: 5rem;
  border: none;
  cursor: pointer;
  padding: 0.5rem 2rem;
  border-radius: 4px;
}
.mainBtnContainer{
  display: flex;
  flex-direction: column-reverse;
  margin-left: 0.5rem;
}
@media (min-width: 616px) and (max-width: 1100px) {
  /* @media only screen and (max-width: 1100px) { */
  .baseNavContainer {
    margin: 0;
  }
  .baseNavContainer > ul > li {
    text-align: center;
  }

  .listSpace {
    display: none;
  }

  .listSpaceActive {
    display: none;
  }

  .siteHeaderLeft {
    display: none;
  }

  .filterContainer {
    width: 100%;
    padding-left: 0rem;
  }

  .btnContainer {
    margin-top: 1rem;
    padding-right: 0rem;
  }

  .tooltip {
    display: none;
    position: absolute;
    top: 10;
    left: 10;
    padding: 0.7rem;
    border: solid 1px var(--global-border-color);
    border-radius: 4px;
    background-color: rgb(0, 0, 0);
    --webkit-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  .baseNavContainer > ul {
    margin-top: none;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
  }

  .contentContainer {
    flex-direction: column;
  }

  .baseNavContainer {
    display: flex;
    justify-content: center;
  }

  .mainContainer {
    display: grid;
    grid-template-columns: 1% 99%;
    /* grid-template-rows: 20% 15% auto; */
    margin: 2rem;
  }
  .siteHeader {
    grid-column: 2;
    grid-row: 1;
    display: grid;
    min-height: 5rem;
  }

  .navHeading {
    border-bottom: 6px solid var(--mfg-blue);
    border-left: 0rem;
  }
}

@media only screen and (max-width: 615px) {
  .filterContainer {
    display: flex;
  }

  .rightContainer {
    padding-left: 0rem;
  }

  .btnContainer {
    margin-top: 1.2rem;
    margin-bottom: 0.6rem;
    justify-content: center;
  }
  .baseNavContainer > ul > {
    display: flex;

    flex-direction: column;
  }
  .searchContainer {
    margin: auto;
    width: 18.7rem;
  }

  .mainContainer {
    display: grid;
    grid-template-columns: 1% 99%;
    grid-template-rows: 20% auto auto;
    margin: 2rem;
  }
  .siteHeader {
    grid-column: 2;
    grid-row: 1;
    margin-bottom: 5rem;
  }
  .featureButton {
    width: 10rem;
  }
}

@import url("../../shared/Fonts/fonts.css");

:root {
  /* Color varibales */
  --main-bg-color: rgba(250, 250, 250, 1);
  --mgf-blue: #003875;
  --whiteish-textColor: #ffffffe6;
  --pure-white: #ffffff;
  --dropdown-grey: rgb(46, 12, 12);
  --light-black: rgba(0, 0, 0, 0.08);
  --light-grey: rgba(236, 230, 230, 0.774);
  --grey: #444444;
  --grey-textColor: #999999;
  --light-white: rgba(255, 255, 255, 0.7);

  /* Font size varibales */
  --hoc-09rem: 0.9rem;
  --hoc-1rem: 1rem;
}

.tab {
  flex: 1;
  display: flex;
  /* UI Properties */
  font-family: "Roboto-Regular";
  font-size: var(--hoc-1rem);
  font-weight: normal;
  color: var(--grey-textColor);
  text-align: left;
  letter-spacing: 0.16px;
  text-decoration: none;
  padding: 1rem;
}

.activeTab {
  color: var(--mgf-blue);
  font-weight: bold;
  font-family: "Roboto-Regular";
  border-bottom: solid 4px var(--mgf-blue);
}

.navbar {
  position: relative;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  /* height: 250px; */
  overflow: hidden;
}

.toggleButton {
  background-color: var(--white);
  outline: none;
  height: 2rem;
  width: 3rem;
  border: none;
  display: none;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.topUserHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0rem 0rem 0.1rem 0.1rem;
}

.mfgLogo {
  width: 15rem;
  height: auto;
  object-fit: contain;
}

.avatar {
  vertical-align: middle;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  cursor: pointer;
  text-transform: uppercase;
}

.avatarLetters {
  border: 1px solid var(--light-black);
  background: var(--light-grey);
  color: var(--dark-gray);
  font-size: var(--hoc-small);
  font-weight: 600;
  font-family: "Roboto-Regular";
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
}
.avatarLetters > img {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;

}
.userInfo {
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 1rem;
}

.userName {
  color: var(--grey);
  font-family: "Roboto-Medium";
  font-size: var(--hoc-1rem);
}

.userRole {
  color: var(--grey);
  font-family: "Roboto-Medium";
  font-size: var(--font-small);
}

.userInfoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-top: 0.5rem;
}

.headerContainer {
  box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 8%);
  -webkit-box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 8%);
  -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  position: sticky;
  width: 100vw;
  top: 0;
  z-index: 10;
  background: var(--pure-white);
}

.navbarCollapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.logOutContainer {
  margin: 0 0 0 1rem;
}

.logoutTooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--pure-white);
  position: absolute;
  border: 1px solid var(--grey-textColor);
  border-radius: 0.5rem;
  width: 8rem;
  right: 2rem;
  margin: 0.5rem 0 0 0;
  cursor: pointer;
}

.logoutTooltip > p {
  margin: 0;
  padding: 0.5rem;
  font-size: var(--hoc-1rem);
  color: var(--grey-textColor);
}

.logoutTooltipDisabled {
  display: none;
}

.logOutContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logOutBtn,
.logOutContainer {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.logOutBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
}

.logoutIcon {
  object-fit: contain;
  margin: 0 0 0 1rem;
  width: 1rem;
  height: 1rem;
}

@media (min-width: 992px) {
  .navbarExpandLG {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbarExpandLG .navbarCollapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar {
    height: min-content !important;
  }
}

@media (max-width: 992px) {
  .toggleButton {
    display: block;
  }
}

.timePickerContainer {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: self-end;
}

.displayBox {
    border: none;
    margin-left: -0.56rem;
    padding-top: 0rem;
    margin-top: -0.3rem;
    color: var(--dark-grey);
    font-family: "Roboto-Medium";
    font-size: var(--base-08rem);
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight:bolder;
    margin: 0.2rem;
    padding-bottom: 1rem;
  }

  .timeFieldContainer {
    display: flex;
    flex-direction: column;
    margin: 0 1rem 0 0;
    width: 3rem;
  }
  
  .timeFieldContainer > p {
    text-align: left;
    color: var(--form-label);
    font-size: var(--base-08rem);
    font-family: "Roboto-Regular";
  }

  .fieldRowContainer{
    display: flex;
    width:40%;
  }

  .editTimePickerContainer{
    width: 6rem;
    height: 2rem;
  }

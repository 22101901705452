@import url("../../shared/Fonts/fonts.css");

:root {
  --main-bg-color: rgba(250, 250, 250, 1);
  --main-border-color: rgba(0, 0, 0, 0.12);
  --pure-white: rgba(255, 255, 255, 1);
  --mfg-blue: rgba(0, 56, 117, 1);
  --mfg-red: rgba(226, 55, 63, 1);
  --green-status: rgba(41, 219, 71, 1);
  --black-textColor: rgba(68, 68, 68, 1);
  --box-shadow: rgba(0, 0, 0, 0.2);
  --gray-status: rgba(153, 153, 153, 1);
  --inactive-status: rgba(255, 74, 74, 1);

  --search-placeholder: rgba(170, 170, 170, 1);
  --record-count: rgba(102, 102, 102, 1);
  --blue-companyHeader: rgba(51, 95, 144, 1);
  --border-color: rgba(204, 204, 204, 1);

  --company-08: 0.8rem;
  --company-1rem: 1rem;
  --comapany-12rem: 1.2rem;

  --border-radius: 0.25rem;
  --letter-spacing: 0.09rem;
}

.mainCompanyContainer {
  width: 100%;
  background-color: var(--pure-white);
}

.headerContainer {
  width: 100%;
  height: 3rem;
  background-color: var(--blue-companyHeader);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  color: var(--pure-white);
  font-size: var(--company-08);
  font-family: "Roboto-Medium";
  display: flex;
  justify-content: left;
  padding: auto;
}

.headerText {
  height: 100%;
  vertical-align: center;
  margin-top: 1rem;
  margin-left: 1rem;
}

.contentContainer {
  display: flex;
  width: 100%;
  padding-top: 1.5rem;
  overflow-x: auto;
}

.rightContainer {
  width: 100%;
  padding-left: 2rem;
}

.leftContainer {
  margin-top: 5.1rem;
}

.detailContainer {
  padding: 1.3rem;
}

.formHeader {
  display: flex;
  justify-content: space-between;
}


.crossIcon {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: right;
}

.subImageContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0.8rem;
  background-color: rgb(248, 248, 248);
}


.logo > img {
  width: 12rem;
  object-fit: contain;
}

.subImageContainerHide {
  display: none;
}

.editBtn {
  border: none;
  background: transparent;
}

hr {
  border: none;
  border: 1px solid var(--border-color);
  padding: 0rem;
  margin-top: 0.9rem;
}

.formFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
}

.formField {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
}

.fieldContainer {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.imageContainer {
  margin: 0 0 0 1rem;
  display: flex;
  justify-content: left;
  width: 274px;
}

.btnAddImage {
  border: none;
  padding: 0rem;
  background: transparent;
  float: left;
}

.btnAddImage > p {
  font-family: "Roboto-Medium";
  font-size: var(--company-1rem);
  color: var(--mfg-blue);
}

.logoTitle {
  color: var(--form-label);
  font-size: var(--company-08);
  font-family: "Roboto-Regular";
  display: flex;
}
.selectLable{
  position: absolute;
  color: var(--dark-grey);
  padding: 10px;
}

@media only screen and (max-width: 963px) {
  .contentContainer {
    flex-direction: column;
  }

  .rightContainer {
    margin-top: 2rem;
    padding-left: 0rem;
  }

  .leftContainer {
    margin-top: 0rem;
  }
}
@media only screen and (max-width: 768px){
  .imageContainer{
    margin: auto;
  }
}
@media only screen and (max-width: 600px) {
  .formFields {
    flex-direction: column;
    justify-content: center;
  }

  .rightContainer {
    padding-left: 0rem;
  }
  .imageContainer{
    margin:none;
    margin-left: 1rem;
  }
}

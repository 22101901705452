@import url("../../shared/Fonts/fonts.css");

:root {
  --white: rgba(255, 255, 255, 1);
  --grey: rgba(169, 169, 169, 0.64);
  --geyishborder: rgba(0, 0, 0, 0.18);
  --Redish: rgba(226, 55, 63, 1);
  --Height5VH: 5vh;
  --size100: 100%;
  --small-text: 0.8rem;
  --medium-text: 1rem;


  /* Font size varibales */
  --login-08rem: 0.8rem;
  --login-13rem: 1.3rem;
}

.entireScreen {
  display: flex;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.middleDiv {
  backdrop-filter: blur(40px);
  width: 22rem;
}

.flexProprty {
  display: flex;
}

.passwordImage {
  height: 1.1rem;
  width: 1.1rem;
  object-fit: contain;
  align-self: center;
  padding: 0.2rem 0.5rem 0.2rem 0.2rem;
}

input::-ms-reveal,
input::-ms-clear {
  opacity: 0;
}

.loginLabel {
  font-size: var(--login-13rem);
  font-family: "Roboto-Regular";
  text-align: center;
  letter-spacing: 1px;
  color: var(--white);
  opacity: 1;
}

.logo {
  object-fit: contain;
  animation: mymove 3s infinite;
}

@keyframes mymove {
  from { width: 20rem;}
  to {  width: 24rem;}
}

#logoAnimate {animation-timing-function: ease-in;}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.logoDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rememberDiv {
  color: var(--white);
  opacity: 1;
}

.hideError {
  display: none;
}

.emptyDiv {
  height: var(--Height5VH);
  line-height: var(--Height5VH);
  padding-left: 2%;
}

.form {
  margin: 1rem;
}

.formSpace {
  margin-bottom: 1rem;
}

.resendOTP {
  float: right;
}

.inputField {
  display: flex;
  width: 17rem;
  height: 2rem;
  border: 1px solid #ffffffcc;
  opacity: 1;
  outline: none;
  color: var(--white);
  background-color: transparent;
}

.inputField > button,
.mandatoryFieldBorder > button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.passwordIcon {
  object-fit: contain;
  width: 20px;
  margin: 0 0.5rem 0 0;
}

.innerInputField {
  width: 95%;
  height: 2rem;
  border: none;
  opacity: 1;
  outline: none;
  color: var(--white);
  background-color: transparent;
  padding-left: 4%;
}

.checkboxLabel,
.checkBoxRemeber {
  color: var(--white);
  opacity: 0.8;
  font-size: var(--login-08rem);
  font-family: "Roboto-Regular";
  padding-top: 0.3%;
  line-height: 1.8;
  margin-left: 0.4rem;
}

.checkBoxImg {
  width: 1.3rem;
  height: 1.3rem;
  padding-left: 2%;
  cursor: pointer;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.resentOTPContainer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 2vh;
}

.forgotPass {
  background-color: transparent;
  border: none;
  color: var(--white);
  line-height: 1.8;
  opacity: 0.8;
  font-size: var(--login-08rem);
  font-family: "Roboto-Regular";
  margin-bottom: -1rem;
  padding-right: 0.4rem;
}

.forgotPass > label {
  cursor: pointer;
}

.rememberDiv {
  display: flex;
  flex-grow: 1;
}

.otpButton {
  text-align: center;
  width: 16rem;
  height: 2rem;
  background: var(--Redish) 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 8px var(----geyishborder);
  opacity: 1;
  color: var(--white);
  font-size: var(--login-08rem);
  font-family: "Roboto-Medium";
  border: none;
}

.transparentButton {
  text-align: center;
  width: 17rem;
  height: 2rem;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  color: var(--white);
  font-size: var(--login-08rem);
  border: none;
}

.inputLabel {
  text-align: left !important;
  font-size: var(--login-08rem);
  margin: 0.5rem 0rem 0.5rem 0rem;
  color: var(--white);
}

.inputContainer {
  display: flex;
  flex-direction: column;
  padding: 2%;
}

.verticalLine {
  margin: 3px 10px;
  width: 0rem;
  height: 1rem;
}

.checkBox {
  width: 0.8rem;
  height: 0.8rem;
  background: transparent;
}

.checkbox {
  object-fit: contain;
  background-color: transparent;
  width: 9%;
  display: inline-block;
  margin: 0% 2%;
}

.mandatoryFieldBorder {
  border: 1px solid var(--Redish);
}

.visibilitySet {
  display: none;
}

.errorMessage {
  color: var(--white);
  font-size: var(--login-08rem);
  font-family: "Roboto-Regular";
  line-height: 1.8;
  background-color: var(--Redish);
  text-align: center;
  height: auto;
}

.authContainer {
  padding: 1rem;
}

.landingImage {
  width: 30%;
}

.loginContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 18rem;
  width: 18rem;
  padding: 20px;
  border-radius: 20px;
}

.loginText{
  color: rgb(78, 78, 78);
  margin-top: 0;
}

@import url("../../shared/Fonts/fonts.css");

:root {
  --main-bg-color: rgba(250, 250, 250, 1);
  --pure-white: rgba(255, 255, 255, 1);
  --mfg-blue: rgba(0, 56, 117, 1);
  --black-textColor: rgba(68, 68, 68, 1);
  --box-shadow: rgba(0, 0, 0, 0.08);
  --search-placeholder: rgba(170, 170, 170, 1);
  --record-count: rgba(102, 102, 102, 1);

  --supplier-08rem: 0.8rem;
  --supplier-1rem: 1rem;
  --supplier-12rem: 1.2rem;

  --border-radius: 0.25rem;
  --letter-spacing: 0.09rem;
}

.mainContainer {
  background-color: var(--main-bgColor);
  margin: 1.5rem 5rem 8rem 5rem;
}

.theadHeader > th {
  background-color: var(--tableHeader-bgColor);
  text-align: inherit;
  padding: 0.8rem 0.8rem 0.8rem 0rem;
  color: var(--pure-white);
  font-size: var(--supplier-08rem);
  font-weight: 400;
  height: 1.4rem;
  font-family: "Roboto-Medium";
}

.tableContent {
  height: 1.4rem;
}

.tableContent > td {
  padding-left: 0.2rem;
  width: 2.5rem;
}

.theadHeader > .smallField {
  min-width: 7rem;
}

.theadHeader > .largeField {
  min-width: 9rem;
}

.theadHeader > .midField {
  max-width: 9rem;
  min-width: 6rem;
}

.tableContent :first-child {
  font-family: "Roboto-Medium";
}

.displayBox {
  display: flex;
  align-items: center;
  background-color: var(--pure-white);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: 0.5rem;
  margin: 0;
  width: 14.9rem;
  height: 1.4rem;
  border: none;
  margin-left: -0.5rem;
  padding-top: 0rem;
  margin-top: -0.3rem;
  color: var(--dark-grey);
  font-family: "Roboto-Medium";
  font-size: var(--supplier-08rem);
}

.subSiteFormContainer {
  padding: 0rem;
  margin: 0rem;
}

.subContainer {
  width: 96%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.supplierDetailsTopContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.headerContainer {
  display: flex;
  padding: 0rem;
  margin-left: 0rem;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.navigation {
  display: flex;
  cursor: pointer;
}

.displayNavigateContainer {
  display: flex;
}

.navigation > p {
  font-size: var(--supplier-12rem);
  font-family: "Roboto-Medium";
  color: var(--record-count);
  width: 8rem;
}

.navigation > button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.backBtn {
  background: none;
  border: none;
}

.displayHeaderContainer {
  display: flex;
  flex-direction: column;
  padding-right: 0rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.theadConfigHeaders > th {
  background-color: var(--tableHeader-bgColor);
  padding: 0.8rem 0.8rem 0.8rem 0rem;
  color: var(--pure-white);
  font-size: var(--supplier-08rem);
  font-weight: 400;
  height: 1.4rem;
  font-family: "Roboto-Medium";
  width: 100%;
}

@media only screen and (max-width: 963px) {
  .mainContainer {
    background-color: var(--main-bgColor);
    margin: 1rem;
  }

  .subContainer {
    width: 85%;
  }

  .navHeading {
    border-bottom: 6px solid var(--mfg-blue);
    border-left: 0;
  }
}

@media only screen and (max-width: 600px) {
  .subContainer {
    width: 100%;
  }

  .displayNavigateContainer {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }

  .supplierDetailsTopContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

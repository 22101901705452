@import url("../../shared/Fonts/fonts.css");
:root {
  /* Color varibales */
  --invoice-border-color: rgba(0, 0, 0, 0.08);
  --pure-white: rgba(255, 255, 255, 1);
  --background-color: rgba(250, 250, 250, 1);
  --grey: rgb(90, 87, 87);
  --mfg-red: rgba(226, 55, 63, 1);
  --mfg-blue: rgba(0, 56, 117, 1);
  --btnText-grey: rgba(102, 102, 102, 1);
  --border-grey: rgba(102, 102, 102, 0.671);

  /* Font size varibales */
  --invoice-09rem: 0.9rem;
  --invoice-1rem: 1rem;
  --invoice-17rem: 1.7rem;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--background-color);
}
.mainFilterContainer {
    display: flex;
    justify-content: start;
    margin-right: 2rem;
    margin-bottom: 0.5rem;
    padding-left: 2rem;
}
.tableContainer{
    padding-left: 1rem;
    padding-right: 1rem;
}
@import url("../shared/Fonts/fonts.css");

:root {
  /* Color varibales */
  --main-bgColor: rgba(250, 250, 250, 1);
  --header-grey: rgba(136, 136, 136, 1);
  --profile-img-container-bg: rgba(248, 248, 248, 1);
  --dark-grey: rgba(68, 68, 68, 1);
  --pure-white: rgba(255, 255, 255, 1);
  --global-border-color: rgba(204, 204, 204, 1);
  --mfg-blue: rgba(0, 56, 117, 1);
  --mfg-red: rgba(226, 55, 63, 1);
  --box-shadow: rgba(0, 0, 0, 0.08);

  /* Font size varibales */
  --inputs-09rem: 0.9rem;
  --inputs-1rem: 1rem;
  --inputs-15rem: 1.5rem;
}
.inputContainer{
  display: flex;
  align-items: flex-start;
  margin-right: 1rem;
}

.mainContainer {
  background-color: var(--main-bgColor);
}

.subContainer {
  display: flex;
  flex-direction: column;
  padding: 3rem 8rem;
}

.navigateContainer {
  display: flex;
  align-items: center;
  width: 50%;
  padding-top: 0.5rem;
}

.userDetailsTopContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.defaultPlaceholder {
  display: none;
}
.lableContainer {
  display: flex;
  align-items: center;
}

.navigateContainer > button > p,
.lableContainer > p {
  color: var(--header-grey);
  font-family: "Roboto-Medium";
  font-size: var(--inputs-1rem);
  padding: 0;
  margin: 0;
}

.navigateContainer > button,
.lableContainer > button,
.btnAddImage,
.inputBox > button,
.mandatoryField > button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.passwordIcon {
  object-fit: contain;
  width: 20px;
  margin: 0 0 0 0.5rem;
}

.navigateContainer > button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.backArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);
  object-fit: contain;
  margin-right: 1rem;
}

.mainDetailsContainer > p,
.mainModuleContainer > p {
  text-align: start;
  display: flex;
  color: var(--dark-grey);
  font-family: "Roboto-Medium";
  font-size: var(--inputs-1rem);
}

.screenError {
  color: var(--mfg-red);
  font-family: "Roboto-Regular";
  font-size: var(--inputs-09rem);
}
.invoiceMessage {
  color: var(--dark-grey);
  font-weight: 500;
  font-size: 0.8rem
}

.detailsContainer {
  background-color: var(--pure-white);
  border: 1px solid var(--global-border-color);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0.5rem 2rem 1rem 2rem;
  box-shadow: 0px 1px 8px var(--box-shadow);
}

.topContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
}
.capitalText {
  text-transform: capitalize;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
}

.imageContainer > p,
.btnAddImage > p {
  margin: 1rem 0 0 0;
  padding: 0;
  font-size: var(--inputs-09rem);
}

.imageContainer > p {
  margin: 1rem 0 1rem 0;
  color: var(--dark-grey);
  font-family: "Roboto-Regular";
}

.btnAddImage > p {
  color: var(--mfg-blue);
  font-family: "Roboto-Medium";
}

.selectionBox,
.invoiceSelectionBox {
  display: flex;
  align-items: flex-start;
  margin-right: 1rem;
}

.selectionBox > select,
.invoiceSelectionBox > select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: var(--inputs-09rem);
  font-family: "Roboto-Regular";
  border: 1px solid var(--global-border-color);
  padding: 0rem 1rem 0rem 1rem;
  margin: 0;
  border-radius: 2px;
  width: 17rem;
  height: 2.4rem;
  position: relative;
  color: var(--btnText-grey);
  background: url("../assets/icons/arrowDownFilled.svg") no-repeat;
  background-position: 95% center;
  border-radius: 4px;
  cursor: pointer;
}
.invoiceSelectionBox > select {
  width: 17rem;
}

.selectionBox > select:focus,
.invoiceSelectionBox > select:focus {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("../assets/icons/arrowUpFilled.svg") no-repeat;
  background-position: 95% center;
}

.inputBox,
.mandatoryField,
.dropdownBox,
.dropDownBoxError {
  display: flex;
  align-items: center;
  background-color: var(--pure-white);
  border: 1px solid var(--global-border-color);
  border-radius: 4px;
  padding: 0rem 0.5rem 0rem 0.5rem;
  margin: 0 1rem 0 0;
  width: 16rem;
  height: 2.3rem;
}
.dropdownBox,
.dropDownBoxError {
  justify-content: space-between;
}
.dropdownBox > p,
.dropDownBoxError {
  font-size: var(--inputs-09rem);
  font-family: "Roboto-Regular";
  color: var(--btnText-grey);
}

.dropdownContainer,
.dropDownContainerError {
  display: flex;
  flex-direction: column;
  width: 16.9rem;
  background-color: white;
  font-size: var(--inputs-09rem);
  font-family: "Roboto-Regular";
  position: relative;
  color: var(--btnText-grey);
  border: 1px solid var(--global-border-color);
  border-radius: 4px;
  margin-top: 0.2rem;
  padding: 0rem 0rem 0rem 0.1rem;
  max-height: 5rem;
  overflow: scroll;
  position: absolute;
}
.dropDownBoxError,
.dropDownContainerError {
  border: 1px solid red;
}

.inputBox > input,
.mandatoryField > input {
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  width: 95%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Roboto-Medium";
  font-size: var(--inputs-09rem);
  color: var(--dark-grey);
}
#readOnlyInput {
  color: var(--header-grey);
}

.mandatoryField {
  border: 1px solid var(--Redish);
}

.editInputField{
  display: flex;
  align-items: center;
  background-color: var(--pure-white);
  border: 1px solid var(--global-border-color);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 0rem 0rem 0rem 0.5rem;
  width: 10rem;
  height: 2.2rem;
}

.col1,
.col2,
.col3,
.col4 {
  display: flex;
  flex-direction: column;
}

.detailsRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0.5rem 1rem;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.btnCancel,
.btnDone,
.btnPDFDownload,
.btnDoneDisabled {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 0.5rem 1.8rem;
  margin: 1rem 1rem 1rem 0;
  border-radius: 4px;
  font-size: var(--inputs-09rem);
  font-family: "Roboto-Medium";
  box-shadow: 0px 1px 8px var(--box-shadow);
}
.btnDoneDisabled {
  background-color: rgba(255, 0, 0, 0.37);
  cursor: not-allowed;
}
.btnPDFDownload{
  margin: 0.5rem 1rem 0.5rem 0;
}

.btnCancel, .btnPDFDownload {
  background-color: var(--pure-white);
  color: var(--btnText-grey);
  border: 1px solid var(--header-grey);
}

.btnDone {
  background-color: var(--mfg-red);
  color: var(--pure-white);
  border: 1px solid var(--mfg-red);
}

.profileImageContainer {
  background-color: var(--profile-img-container-bg);
  margin-top: 0.5rem;
}

.cancelContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 0.5rem 0 0;
}

.cancelContainer > img {
  object-fit: cover;
}

.profileImg {
  width: 8rem;
  height: 8rem;
  margin: 0.3rem 1rem 0rem 1rem;
  border-radius: 50%;
  object-fit: cover;
  color: var(--dark-grey);
}


@media (min-width: 768px) and (max-width: 1280px) {
  .subContainer {
    padding: 1rem 1rem;
  }

  .selectionBox > select {
    width: 17rem;
    height: 2.3rem;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .subContainer {
    padding: 1rem 1rem;
  }

  .selectionBox > select {
    width: 17rem;
    height: 2.3rem;
  }

  .detailsContainer {
    display: flex;
    justify-content: center;
  }
  .detailsRow {
    display: flex;
    margin-right: auto;
    flex-wrap: wrap;
    justify-content: center;
  }
  .col1,
  .col2,
  .col3,
  .col4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
}

@media only screen and (max-width: 600px) {
  .subContainer {
    padding: 1rem 1rem;
  }

  .navigateContainer {
    display: flex;
    width: 100%;
  }

  .selectionBox > select {
    width: 17rem;
    height: 2.3rem;
  }

  .inputBox {
    width: 16rem;
    height: 2.3rem;
  }

  .topContainer,
  .imageContainer {
    display: flex;
    flex-direction: column;
  }
  .userDetailsTopContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .detailsContainer {
    display: flex;
    justify-content: center;
  }

  .col1,
  .col2,
  .col3,
  .col4 {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

@import url("../../shared/Fonts/fonts.css");

:root {
  /* Color varibales */
  --main-bg-color: rgba(250, 250, 250, 1);
  --mgf-blue: #003875;
  --whiteish-textColor: #ffffffe6;
  --pure-white: #ffffff;
  --adminTable-border-color: #ccc;
  --dropdown-grey: rgb(46, 12, 12);
  --box-shadow: rgba(0, 0, 0, 0.08);
  --card-borderColor: rgba(204, 195, 195, 0.596);
  --card-amountColor: #444444;
  --grey-textColor: #999999;
  --completedIcon-bgColor: #26cc5d3a;
  --pendingIcon-bgColor: #ffa6004b;
  --exportFileIcon-bgColor: #52a6ff38;
  --mailIcon-bgColor: #9c59b636;
  --light-white: rgba(255, 255, 255, 0.7);

  /* Font size varibales */
  --dashboard-09rem: 0.9rem;
  --dashboard-1rem: 1rem;
  --dashboard-17rem: 1.7rem;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  height: 200%;
  width: 100%;
  background-color: var(--main-bg-color);
}

.leftContainer {
  display: flex;
  flex-direction: column;
  flex: 0.25;
  width: 15%;
  background-color: var(--mgf-blue);
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 1vw;
  width: 85%;
  margin: 0 1rem 0 1rem;
  background-color: var(--main-bg-color);
}

.leftFilterText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 2rem;
  font-family: "Roboto-Medium";
}

.mainFilterText {
  text-transform: uppercase;
  color: var(--light-white);
  font-size: var(--dashboard-09rem);
  letter-spacing: 0.09rem;
}

.filterText {
  text-transform: capitalize;
  color: var(--whiteish-textColor);
  font-size: var(--dashboard-09rem);
  font-family: "Roboto-Regular";
  margin-bottom: 0.5rem;
}

.filterContainer {
  margin: 0px;
  padding: 0px;
}

.filterContainer > div > p {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: flex-start;
}

.btnClearFilters {
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  color: var(--pure-white);
  font-size: var(--dashboard-09rem);
  letter-spacing: 0.09rem;
  font-family: "Roboto-Medium";
}

.axesContainer > select,
.moduleContainer > select,
.siteContainer > select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: var(--dashboard-09rem);
  font-family: "Roboto-Regular";
  padding: 0.5rem 0rem 0.5rem 0.5rem;
  border: 1px solid var(--light-white);
  border-radius: 4px;
  width: 100%;
  position: relative;
  color: var(--pure-white);
  background: url("../../assets/icons/arrowDownLight.svg") no-repeat;
  background-position: 95% center;
}

.axesContainer > select:focus,
.moduleContainer > select:focus,
.siteContainer > select:focus {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("../../assets/icons/arrowUp.svg") no-repeat;
  background-position: 95% center;
}

.axesContainer > select > option,
.moduleContainer > select > option,
.siteContainer > select > option {
  background-color: var(--pure-white);
  color: var(--dropdown-grey);
  font-size: var(--dashboard-09rem);
  font-family: "Roboto-Regular";
}

.dateContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.fromDate,
.toDate {
  min-width: 7rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
}

.cardMainContainer {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  margin: 1rem 0rem 1rem 0rem;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  width: 17.5rem;
  height: 7rem;
  box-shadow: 0px 1px 8px var(--box-shadow);
  border: 0.01px solid var(--card-borderColor);
  border-radius: 4px;
  opacity: 1;
  margin-right: 1rem;
}

.cardTopContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70%;
}

.cardBottomContainer {
  height: 30%;
}

.cardAmount,
.cardText {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: start;
}

.cardAmount {
  display: flex;
  font-size: var(--dashboard-17rem);
  color: var(--card-amountColor);
  font-family: "Roboto-Bold";
  margin: 2.6rem 0 0rem 1rem;
}

.cardText {
  font-size: var(--dashboard-09rem);
  font-family: "Roboto-Regular";
  color: var(--grey-textColor);
  letter-spacing: 0.04rem;
  margin: 0 0rem 0.9rem 1rem;
}

.circularIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0.8rem 0.4rem 0rem 0rem;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .mainContainer {
    display: flex;
    flex-direction: row;
  }

  .leftContainer {
    width: 30%;
  }

  .rightContainer {
    display: flex;
    flex-direction: column;
    width: 70%;
  }

  .dateContainer {
    display: block;
  }

  .leftFilterText {
    padding: 0.5rem;
  }

  .filterContainer {
    padding: 0.5rem;
  }

  .cardMainContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .cardContainer {
    height: 7rem;
    margin-top: 1rem;
    min-width: 15rem;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .mainContainer {
    display: flex;
    flex-direction: row;
  }

  .leftContainer {
    width: 40%;
  }

  .rightContainer {
    display: flex;
    flex-direction: column;
    width: 60%;
  }

  .cardMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cardContainer {
    margin-top: 1rem;
    width: 20rem;
  }
}

@media only screen and (max-width: 600px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    width: 100%;
    padding: 0 0 2rem 0;
  }

  .leftContainer {
    width: 100%;
    padding: 0rem;
    margin: 0rem;
  }

  .rightContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .cardMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cardContainer {
    margin-top: 1rem;
    width: 20rem;
  }

  .leftFilterText {
    padding: 0.5rem;
  }

  .filterContainer {
    padding: 0.5rem;
  }

  .fromDate,
  .toDate {
    min-width: 7rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 0.5rem 0 0;
    justify-content: space-between;
  }
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
  }
}

:root {
  /* Color varibales */
  --invoice-border-color: rgba(0, 0, 0, 0.08);
  --pure-white: rgba(255, 255, 255, 1);
  --background-color: rgba(250, 250, 250, 1);
  --grey: rgb(90, 87, 87);
  --mfg-red: rgba(226, 55, 63, 1);
  --mfg-blue: rgba(0, 56, 117, 1);
  --btnText-grey: rgba(102, 102, 102, 1);
  --border-grey: rgba(102, 102, 102, 0.671);

  /* Font size varibales */
  --module-09rem: 0.9rem;
  --module-1rem: 1rem;
  --module-17rem: 1.7rem;
}

.mainContainer {
  background-color: var(--background-color);
  margin: 0;
  padding: 0 5rem 0 5rem;
}

.filtersContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 0px;
}

.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 40%;
}

.filterContainerOne {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding-left: 16rem;
}

.hiddenTag {
  text-transform: capitalize;
  color: var(--grey);
  font-family: "Roboto-Regular";
  margin-bottom: 0.4rem;
  display: flex;
  visibility: hidden;
}

.featureButton,
.createBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2rem;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 4px;
}

.featureButton {
  width: 8rem;
  background-color: var(--mfg-blue);
}

.createBtn {
  background-color: var(--mfg-red);
}

.btnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btnContainer > p,
.createBtn > p {
  color: var(--pure-white);
}

.btnContainer > p {
  margin: 0 0.5rem 0 0;
  padding: 0;
}

.inputContainer {
  flex: 1;
}

.row1,
.row2,
.row3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0.8rem 0 0 0;
  width: 100%;
}

.row3 {
  margin-bottom: 1rem;
}

.displayText {
  text-transform: capitalize;
  color: var(--btnText-grey);
  font-size: var(--module-09rem);
  font-family: "Roboto-Regular";
  margin: 0.8rem 0 1rem 0;
  padding: 0;
  display: flex;
  color: var(--dark-grey);
  margin: 0;
  padding: 0;
}

.moduleFilterContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .mainContainer {
    background-color: var(--background-color);
    padding: 0 2rem 0 2em;
  }

  .filterContainerOne {
    /* width: 80%; */
  }

  .moduleFilterContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .mainContainer {
    background-color: var(--background-color);
    padding: 0 1rem 0 1rem;
  }

  .filterContainerOne {
    width: 100%;
  }

  .moduleFilterContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 600px) {
  .mainContainer {
    background-color: var(--background-color);
    padding: 0 1rem 0 1rem;
  }

  .topContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .filtersContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 1rem 0 0;
  }

  .filterContainerOne {
    width: 100%;
  }

  .buttonsContainer {
    display: flex;
    width: 100%;
  }

  .moduleFilterContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
  }

  .featureButton,
  .createBtn {
    width: 10rem;
  }

  .inputContainer {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    width: 100%;
  }

  .row1,
  .row2,
  .row3 {
    display: flex;
    flex-direction: column;
    margin: 0rem 0 0 0;
    width: 100%;
  }

  .displayText {
    margin: 0rem 0 0rem 0.5rem;
  }
}

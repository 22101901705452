:root {
  /* Color varibales */
  --box-shadow: rgba(0, 0, 0, 0.116);
  --invoice-tableHeader-bgColor: rgb(13, 63, 121);
  --pure-white: rgba(255, 255, 255, 1);
  --tableNthRowColor: rgba(240, 243, 247, 1);
  --table-border-color: #ccc;
  --activated-green: rgba(41, 219, 71, 1);
  --deactivated--red: rgba(255, 74, 74, 1);
  --recordCount--gray: rgba(102, 102, 102, 1);
  --table-border-radius: 0.25rem;
  --pagintionNumber-color: rgba(68, 68, 68, 1);
  --pureblack: rgb(0, 0, 0);

  /* Font size varibales */
  --invoice-table-07rem: 0.7rem;
  --invoice-table-08rem: 0.8rem;
  --invoice-table-09rem: 0.9rem;
}

.invoiceTableContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  overflow-x: auto;
}

.invoiceMainTable > thead,
.invoiceMainTable > tbody {
  display: block;
}
.invoiceMainTable > tbody {
  max-height: 10rem;
  overflow-y: auto;
  overflow-x: auto;
}

.invoiceHeader,
.invoiceHeaderDesc {
  background-color: var(--invoice-tableHeader-bgColor);
  text-align: start;
  padding: 0.5rem 1rem;
  color: var(--pure-white);
  font-size: var(--invoice-table-09rem);
  font-weight: 500;
  letter-spacing: 0.02rem;
  font-family: "Roboto-Medium";
  white-space: nowrap;
}
.invoiceHeaderDesc {
  width: 12rem;
}

.tblRow {
  cursor: unset;
  margin: 20rem;
  background-color: rgba(250, 250, 250, 1);
}

.dataCell > input {
  border: 1px solid var(--pagintionNumber-color);
  font-family: "Roboto-Medium";
  font-size: var(--invoice-table-08rem);
  color: var(--pagintionNumber-color);
  padding: 0.2rem;
  max-width: 3rem;
  max-height: 2rem;
}

.dataCell,
.dataCellHighLighted {
  text-align: start;
  padding: 0.2rem 1.5rem;
  font-size: var(--invoice-table-08rem);
  font-weight: 600;
  font-family: "Roboto-Regular";
  letter-spacing: 0.02rem;
  color: var(--pureblack);
  width: 4rem;
  white-space: nowrap;
}
.dataCell > div {
  display: flex;
}
.dataCell > div > img {
  height: 0.8rem;
  width: 0.8rem;
  float: right;
  margin-left: auto;
  padding-left: 0.2rem;
}
.dataCellHighLighted > div {
  display: flex;
}
.dataCellHighLighted > div > img {
  height: 0.8rem;
  width: 0.8rem;
  float: right;
  margin-left: auto;
  padding-left: 0.2rem;
}
.dataCellHighLighted {
  color: var(--deactivated--red);
  white-space: nowrap;
}
.dataCellHighLightedInDetails {
  text-align: start;
  padding: 0.2rem 1.5rem;
  font-size: var(--invoice-table-08rem);
  font-weight: 600;
  font-family: "Roboto-Regular";
  letter-spacing: 0.02rem;
  color: var(--pureblack);
  /* width: 4rem; */
  white-space: nowrap;
}

.tblRow:nth-child(even) {
  background-color: rgba(250, 250, 250, 1);
}

@import url("../../shared/Fonts/fonts.css");

:root {
  --main-bgColor: rgba(250, 250, 250, 1);
  --light-grey: rgba(136, 136, 136, 1);
  --dark-grey: rgba(68, 68, 68, 1);
  --pure-white: rgba(255, 255, 255, 1);
  --border-color: rgba(204, 204, 204, 1);
  --mfg-blue: rgba(0, 56, 117, 1);
  --mfg-red: rgba(226, 55, 63, 1);
  --box-shadow: rgba(0, 0, 0, 0.2);
  --form-label: rgba(102, 102, 102, 1);
  --hr-line: rgba(208, 208, 208, 1);
  --binBtn-bg: white;

  --border-radius: 0.25rem;
  --letter-spacing: 0.09rem;

  --role-08rem: 0.8rem;
  --role-1rem: 1rem;
  --role-12rem: 1.2rem;
}

.selectionBox {
  border: none;
  display: flex;
  align-items: center;
  background-color: var(--pure-white);
  border-radius: var(--border-radius);
  margin: 0;
  float: left;
}

.selectionBox > select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  padding: 0rem 0.5rem 0rem 0.5rem;
  margin: 0;
  width: 10rem;
  height: 2.3rem;
  position: relative;
  color: var(--form-label);
  font-family: "Roboto-Medium";
  font-size: var(--role-08rem);
  background: url("../../assets/icons/arrowDownFilled.svg") no-repeat;
  background-position: 95% center;
  border-radius: var(--border-radius);
  background-color: white;
  border: 1px solid var(--global-border-color);
  cursor: pointer;
}

.selectionBox > select:focus {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-position: 95% center;
}

.formFieldContainer {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: 1.12rem;
}

.formFieldContainer > p {
  display: flex;
  justify-content: left;
  float: left;
}

.leftFilterContainer {
  display: flex;
  flex-direction: row;
}

.rightFilterContainer {
  padding-top: 2.5rem;
}

.addContainer > button {
  width: 5rem;
  height: 2.2rem;
  background-color: var(--mfg-red);
  border: none;
  box-shadow: 0px, 0px, 6px var(--box-shadow);
  border-radius: var(--border-radius);
}
.siteForm {
  display: none;
}

.siteForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: var(--pure-white);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  justify-content: start;
  padding: 1rem;
  box-shadow: 0px 1px 8px var(--box-shadow);
}

.siteForm > div {
  margin: 0.5rem;
  display: flex;
}

@media only screen and (max-width: 615px) {
  .siteForm {
    overflow-x: auto;
    width: 100%;
    justify-content: space-around;
  }

  .rightFilterContainer {
    display: flex;
    padding-top: 0rem;
    justify-content: center;
  }

  .addContainer > button {
    margin-left: 0rem;
  }

  .leftFilterContainer {
    flex-direction: column;
  }

  .formFieldContainer {
    margin: auto;
    width: 9rem;
  }

  .selectionBox {
    width: 9rem;
  }

  .selectionBox > select {
    width: 100%;
    overflow: hidden;
  }

  .selectionBox > select:focus {
    width: 100%;
  }
}

@import url("../../shared/Fonts/fonts.css");

:root {
  /* Color varibales */
  --main-bgColor: rgba(250, 250, 250, 1);
  --header-grey: rgba(136, 136, 136, 1);
  --profile-img-container-bg: rgba(248, 248, 248, 1);
  --dark-grey: rgba(68, 68, 68, 1);
  --pure-white: rgba(255, 255, 255, 1);
  --input-border-color: rgba(204, 204, 204, 1);
  --mfg-blue: rgba(0, 56, 117, 1);
  --mfg-red: rgba(226, 55, 63, 1);
  --box-shadow: rgba(0, 0, 0, 0.08);
  --divider-grey: rgba(208, 208, 208, 1);

  /* Font size varibales */
  --addModule-09rem: 0.9rem;
  --addModule-1rem: 1rem;
  --addModule-17rem: 1.7rem;
}

.mainContainer {
  display: flex;
  margin: 2rem 2rem 2rem 2rem;
  scroll-behavior: smooth;
}

.leftContainer,
.leftContainerStation {
  display: flex;
  position: sticky;
  top: 12rem;
  width: 15%;
  max-height: 6rem;
  margin: 0 1rem 0 0;
}

.leftContainerStation {
  top: 14.8rem;
  max-height: 8rem;
}

.innerSideContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  border-right: 1px solid var(--divider-grey);
  padding: 0 1rem 0 0;
  background-color: rgba(250, 250, 250, 1);
}

.displayContainer {
  width: 18rem;
}

.inactiveText {
  color: var(--header-grey);
  text-decoration: none;
  font-family: "Roboto-Regular";
  font-size: var(--medium-font);
  cursor: pointer;
}

.activeText {
  font-family: "Roboto-Medium";
  text-decoration: none;
  color: var(--dark-grey);
  font-size: var(--medium-font);
}

.rightContainer {
  display: flex;
  flex: 2;
  flex-direction: column;
}

.inputBox,
.mandatoryField {
  display: flex;
  align-items: center;
  background-color: var(--pure-white);
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  padding: 0rem 0.5rem 0rem 0.5rem;
  margin: 0;
  width: 16rem;
  height: 2.3rem;
}

.mandatoryField {
  border: 1px solid var(--mfg-red);
}

.detailsTopContainer,
.fieldDetailsBlock {
  display: flex;
  background-color: aliceblue;
  background-color: var(--pure-white);
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 1rem 1rem 1rem;
  box-shadow: 0px 1px 8px var(--box-shadow);
}

.detailsTopContainerHidden,
.innerFieldContainerHidden {
  display: none;
}

.deleteContainer {
  display: flex;
  justify-content: end;
  flex: 1;
  padding-top: 1rem;
}

.fieldDetailsBlock {
  margin: 0 0 1rem 0;
}

.innerBlock {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerContainer > p {
  color: var(--dark-grey);
  font-family: "Roboto-Medium";
  font-size: var(--addModule-1rem);
}

.downArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  margin-right: 1rem;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1.8rem;
}

.btnContainer{
  display: flex;
  justify-content: flex-end;
}

.headerContainer > button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.btnCancel,
.btnDone {
    background: none;
    font: inherit;
    color: inherit;
    padding: 0;
    cursor: pointer;
    outline: inherit;
    width: 5rem;
    height: 2.2rem;
    background-color: var(--mfg-red);
    border: none;
    box-shadow: 0px, 0px, 6px var(--button-shadow);
    border-radius: var(--border-radius);
    margin-left: 0.62rem;
}

.btnCancel {
  background-color: var(--pure-white);
  color: var(--btnText-grey);
  border: 1px solid var(--header-grey);
}

.btnDone {
  background-color: var(--mfg-red);
  color: var(--pure-white);
  border: 1px solid var(--mfg-red);
}

.viewDetailsTopContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--pure-white);
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 1rem 1rem 1rem;

  box-shadow: 0px 1px 8px var(--box-shadow);
}

.viewFieldDetailsBlock {
  display: flex;
  background-color: aliceblue;
  /* justify-content: space-between; */
  background-color: var(--pure-white);
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  padding: 0 1rem 1rem 1rem;
  margin: 0 0 2rem 0;
  box-shadow: 0px 1px 8px var(--box-shadow);
}

.divider {
  margin: 1rem 0 1rem 0;
  height: 0.1rem;
  background-color: var(--divider-grey);
}

.moduleDetailsTopContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkboxDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 65%;
}

.checkboxInput {
  display: flex;
}

.inputContainer{
  display: flex;
}

.displayCheckboxContainer{
  display: flex;
  width: 18rem;
}

.exportButtons{
  align-items: center;
  color: var(--btnText-grey);
  font-size: var(--global-09rem);
  font-family: "Roboto-Regular";
  margin: 0.8rem 1rem 0.8rem 0;
  padding: 0;
  display: flex;
}

.actionBtnContainer{
  display: flex;
  justify-content: flex-end;
  align-items: end;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    margin: 2rem;
  }

  .leftContainer,
  .leftContainerStation {
    display: flex;
    width: 100%;
    max-height: 6rem;
    margin: 0rem 0rem 0rem 0rem;
    z-index: 0;
    bottom: 0;
  }

  .innerSideContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    border-right: none;
    border-bottom: 1px solid var(--divider-grey);
    padding: 0 1rem 0 0;
  }

  .detailsTopContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .innerBlock {
    display: flex;
    /* justify-content: space-evenly; */
    /* align-items: center; */
    width: 100%;
    flex-wrap: wrap;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    margin: 2rem;
  }

  .leftContainer,
  .leftContainerStation {
    display: flex;
    background-color: #fff;
    border-radius: 4px;
    z-index: 1;
    top: 0rem;
    width: 100%;
    max-height: 6rem;
    margin: 0 0rem 2rem 0;
  }

  .innerSideContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    border-right: none;
    border-bottom: 1px solid var(--divider-grey);
    padding: 0 1rem 0 0;
  }

  .detailsTopContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .innerBlock {
    display: flex;
    /* justify-content: space-between;
    align-items: center; */
    width: 100%;
    flex-wrap: wrap;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    margin: 2rem;
  }

  .leftContainer,
  .leftContainerStation {
    display: flex;
    top: 0rem;
    width: 100%;
    max-height: 6rem;
    margin: 0 0rem 2rem 0;
  }

  .innerSideContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    border-right: none;
    border-bottom: 1px solid var(--divider-grey);
    padding: 0 1rem 0 0;
  }

  .viewDetailsTopContainer,
  .viewFieldDetailsBlock {
    display: flex;
    justify-content: center;
  }

  .innerBlock {
    display: flex;
    /* justify-content: space-between;
    align-items: center; */
    width: 100%;
    flex-direction: column;
  }

  .fieldDetailsBlock,
  .detailsTopContainer {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    margin: 2rem;
  }

  .leftContainer,
  .leftContainerStation {
    display: flex;
    top: 0rem;
    width: 100%;
    max-height: 6rem;
    margin: 0 0rem 2rem 0;
    display: none;
  }

  .innerSideContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    border-right: none;
    border-bottom: 1px solid var(--divider-grey);
    padding: 0 1rem 0 0;
  }

  .rightContainer {
    display: flex;
    flex: 2;
    flex-direction: column;
  }

  .innerBlock {
    display: flex;
    /* justify-content: space-between;
    align-items: center; */
    width: 100%;
    flex-direction: column;
  }

  .fieldDetailsBlock,
  .detailsTopContainer {
    display: flex;
    justify-content: center;
  }
}
